.customModal {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.78);
    z-index: 2;
    display: flex;
    justify-content: center;
}

.input-area {
    position: relative;
    display: flex;
    align-items: center;
    svg {
        cursor: pointer;
    }
}

.activeTab {
    color: #171832 !important;
}

.inputBoxArea {
    display: flex;
    flex-direction: column;
    width: 49%;
    margin-bottom: 20px;
    input {
        padding: 20px;
        width: 100%;
        background-color: #f8f8f8;
        border: none !important;
        height: 50px;
        font-size: 14px;
        &:focus {
            outline: none !important    ;
        }
    }
    textarea {
        padding: 20px;
        min-width: 100%;
        background-color: #f8f8f8;
        border: none !important;
        font-size: 14px;
        &:focus {
            outline: none !important    ;
        }
    }
    @media (max-width: 850px) {
        width: 100%;
    }
    select {
        height: 50px;
        border: none;
        background-color: #f8f8f8;
        font-size: 14px;
    }
}

.inputBoxArea-checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 20px;
    label {
        display: flex;
        span {
            margin-left: 5px;
        }
    }
    &--text-link {
        color: #7047ee;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}

.withdrawModal {
    background-color: #fff;
    border-radius: 15px;
    width: 550px;
    height: max-content;
    z-index: 5;
    @media (max-width: 750px) {
        width: auto;
    }
    &--top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        &--title {
            font-size: 35px;
            font-weight: bold;
            color: #171832;
            @media (max-width: 750px) {
                font-size: 22px;
            }
        }
    }
    &--mid {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .inputBoxWithoutForm {
            width: 100%;
        }
        @media (max-width: 750px) {
            .inputBoxWithoutForm {
                width: 100%;
            }
        }
    }
    &--bot {
        .inputBoxWithoutForm {
            width: 100%;
        }
        &--infoSec {
            font-size: 14px;
            color: #717181;
            margin-top: 20px;
            &--topSpan {
                margin-bottom: 25px;
            }
            span {
                display: block;
            }
            &--rulesTitle {
            }
            &--rule {
            }
        }
    }
    &--wantBonus {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 50px;
        width: 100%;
        background-color: #f8f8f8;
        font-size: 14px;
        color: rgba(23, 24, 50, 0.6);
        padding: 0 20px;
        border-radius: 5px;
        margin-top: 10px;
    }
    &--btns {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .customBtn {
            margin: 0;
        }
    }
    &--promotionCodeResult {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        img {
            width: 100px;
        }
        span {
            margin-top: 20px;
            font-family: Poppins;
            font-size: 15px;
        }
    }
}

.datepicker {
    .inputBoxArea {
        input {
            height: 10px !important;
            padding: 0 !important;
        }
    }
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    height: 50px !important;
    input {
        background-color: transparent;
    }
}

.wantBonusActive {
    background-image: linear-gradient(87deg, #f29626 0%, #fe6740 100%);
    color: #fff;
}

.inputBoxWithoutForm {
    text-align: left;
    margin-bottom: 10px;
    span {
        display: block;
        color: #171832;
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 10px;
    }
    .form-select {
        height: 50px;
        color: rgba(23, 24, 50, 0.6);
        font-size: 14px;
    }
    input {
        width: 100%;
        background-color: #f8f8f8;
        color: rgba(23, 24, 50, 0.6);
        border: none;
        height: 50px;
        border-radius: 5px;
        font-size: 14px;
        padding-left: 10px;
    }
}

.phoneInput {
    display: flex;
    width: 350px;
    height: 74px;
    @media (max-width: 992px) {
        width: 100%;
    }
    .inputBoxArea {
        width: 250px;
        @media (max-width: 992px) {
            width: 100%;
        }
    }
    &--select {
        margin-top: auto;
        margin-right: 10px;
        height: 50px;
        max-width: 80px;
    }
}

.inputBoxArea {
    position: relative !important;
}
.inputBox-password-show-icon {
    position: absolute;
    right: 10px;
    top: 35px;
    cursor: pointer;
}

.error-show-icon {
    right: 30px !important;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap");

.creditCard-wrapper {
    background-color: #f8f8f8;
    margin-bottom: 20px;
    #field-wrapper {
        background-color: #f8f8f8;
    }
    input {
        background-color: #f8f8f8;
        height: 50px;
    }
}

.prefix-input-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    input {
        padding-left: 40px !important;
    }
    span {
        font-size: 15.5px;
        position: absolute;
        left: 18px;
        z-index: 10;
        top: 44.3px;
    }
}

.cepbank-prefix {
    input {
        padding-left: 50px !important;
    }
    span {
        top: 46px;
    }
}
