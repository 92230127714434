.populer3DGames {
  margin-top: 46px;
  p {
    margin: 0;
  }
  &--title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--showAll {
      display: flex;
      align-items: center;
      min-width: 151px;
    }
  }
}