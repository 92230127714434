.tab {
  &--aboutus {
    span {
      color: #171832;
      font-size: 24px;
      font-weight: bold;
      display: block;
      margin-bottom: 24px;
    }
    p {
      color: #545d7a;
      font-size: 14.4px;
      font-weight: normal;
      margin-bottom: 40px;
    }
  }
}
