.profile {
  margin: 50px 0px;
  display: flex;
  justify-content: space-between;
  &--links {
    width: 17%;
    height: 100%;
    @media (max-width: 991px) {
      display: none;
    }
  }
  &--content {
    width: 81%;
    height: 100%;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  &--mobileSideMenu {
    background-image: linear-gradient(84deg, #863cc4 0%, #1631ba 100%);
    padding-top: 25px;
    .btn-close {
      opacity: 1;
      background-image: url(../../assets/icons/close.svg);
    }
  }
}

.accountSettings {
  width: 100%;
  display: flex;
  flex-direction: column;
  &--top {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &--mid {
    width: 100%;
    margin-top: 30px;
    flex-wrap: wrap;
    span {
      display: block;
      font-size: 16px;
      font-weight: normal;
      color: #171832;
      margin-bottom: 9px;
    }
  }
  &--bot {
    span {
      display: block;
      font-size: 16px;
      font-weight: normal;
      color: #171832;
      margin-bottom: 9px;
    }
  }
  &--options {
    display: flex;
    flex-wrap: wrap;
  }
  &--divider {
    width: 100%;
    height: 1px;
    background-color: #c0c0c7;
    margin: 30px 0;
  }
  &--btns {
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
    .customBtn {
      margin-left: 0;
      margin-right: 15px;
    }
  }
}