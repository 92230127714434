.casino-top {
  width: 100%;
  height: 80px;
  background-color: #333645;
  display: flex;
  align-items: center;
  &--wrapper {
    width: 100%;
  }
}

.casino-option-box {
  cursor: pointer;
  padding: 13.5px 20px;
  font-size: 14px;
  font-weight: normal;
  border: solid 0.5px #fff;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 5px;
  span {
    margin-left: 10px;
    white-space: nowrap;
  }
  font-size: 14px;
  font-weight: normal;
  span {
    margin-left: 10px;
    white-space: nowrap;
  }
}
.selected-option {
  background-image: linear-gradient(84deg, #1631ba 0%, #863cc4 100%) !important;
  border: none !important;
}

.casino-top {
  .slick-list {
    margin: 0 20px !important;
  }
  .slick-slide > div {
    padding: 0 10px !important;
  }

  .slick-prev {
    left: 0px;
  }
  .slick-next {
    right: 0px;
  }
}
.casino-top--wrapper {
  color: #fff;
}

.casino-provider-wrapper {
  margin-top: 17px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  &--providers {
    overflow: hidden;
    border-radius: 5px;
    width: 75%;
    height: 50px;
    background-color: #333645;
    display: flex;
    &--providerBox {
      cursor: pointer;
      width: 45px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        display: none;
      }
    }
    &--providerBox--active {
      cursor: pointer;
      width: 130px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: linear-gradient(84deg, #1631ba 0%, #863cc4 100%);
      color: #fff;
      font-size: 14px;
      span {
        display: block !important;
        margin-left: 5px;
      }
    }
  }
  &--searchbox {
    width: 24%;
    height: 50px;
    position: relative;
    img {
      position: absolute;
      right: 16px;
    }
    input {
      background-image: linear-gradient(84deg, #1631ba 0%, #863cc4 100%) !important;
      border-radius: 5px;
      border: none;
      background-color: #f8f8f8;
      width: 100%;
      height: 50px;
      font-size: 12px;
      color: #fff;
      padding: 20px;
      &::placeholder {
        color: #fff;
        opacity: 0.6;
      }
      &:focus {
        outline: none;
      }
    }
  }
  @media (max-width: 1200px) {
    &--providers {
      width: 60%;
    }
    &--searchbox {
      width: 39%;
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    &--providers {
      width: 100%;
    }
    &--searchbox {
      margin-top: 15px;
      width: 100%;
    }
  }
  @media (max-width: 550px) {
    flex-direction: column;
    &--providers {
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }
}

.providerBoxWrapper {
  display: flex;
  align-items: center;
  &:last-child {
    .divider {
      display: none !important;
    }
  }
}

.divider {
  width: 1px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.15);
}

.games-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 40px;
  &--card {
    position: relative;
    margin-bottom: 10px;
    width: 410px;
    min-height: 230px;
    overflow: hidden;
    img {
      max-width: 100%;
      height: 100%;
    }
    &--providerCard {
      position: relative;
      &--providerbg {
        width: 185px;
        height: 42px;
        background-image: linear-gradient(to right, #813bc3 0%, #1831ba 100%);
        opacity: 0.8;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        position: absolute;
        bottom: 20px;
      }
      &--providerInfo {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 14px;
        padding-left: 20px;
        position: absolute;
        bottom: 27px;
        img {
        }
        span {
          margin-left: 10px;
        }
      }
    }
    &:hover {
      &--providerCard {
        visibility: hidden !important;
      }
    }
    &:hover {
      border-radius: 10px;
      background-image: linear-gradient(to right, #813bc3 0%, #1831ba 100%);
      .gameCard-content {
        display: flex !important;
      }
      img {
        &:hover {
          transition: 0.6s;
          mix-blend-mode: soft-light;
        }
      }
    }
    &--onhover {
      width: 100%;
      height: 100%;
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: -230px;
      &--top {
        color: #fff;
        span {
          margin-left: 5px;
        }
      }
      &--mid {
        color: #fff;
        font-size: 36px;
        font-weight: 600;
      }
      &--bot {
        .customBtn {
          margin-left: 0 !important;
          margin-bottom: 15px;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        &--infoBoxes {
          display: flex;
        }
      }
    }
  }
}

.games-wrapper {
  &--card {
    &:hover {
      .games-wrapper--card--providerCard {
        display: none !important;
      }
      .games-wrapper--card--onhover {
        display: flex;
      }
    }
  }
}

.infoBox {
  width: 121.5px;
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  margin-right: 10px;
  img {
    width: 14px;
    height: 15px;
  }
  span {
    margin-left: 5px;
  }
}

.infoBox-red {
  background-color: #be0404 !important;
  color: #fff;
}

.iframeWrapper {
}

.game-window {
  display: block;
  margin: auto;
  width: 90%;
  height: 80vh;
}

.mobile.casino-body:not(.VR) {
  @media screen and (orientation: landscape) {
    .prevent-landscape {
      display: flex;
    }
  }
}
.casino-games-wrapper {
  overflow: auto;
  scroll-behavior: smooth;
  // background: url(../profile/assets/img/arka768.webp) no-repeat;
  @media (min-width: 768px) {
    // background: url(../profile/assets/img/arka1440.webp) no-repeat;
  }
  @media (min-width: 1440px) {
    // background: url(../profile/assets/img/arka2000.webp) no-repeat;
  }
  .casino-slider {
    padding-bottom: 35px;
    .link-wrapper {
      display: block;
      picture {
        display: block;
      }
    }
    .carousel .slider-wrapper {
      height: 198px;
    }
  }
  .title-wrapper {
    background-image: linear-gradient(104deg, var(main-explosive) -1%, #330e60 101%);
    padding: 0 0 0 19px;
    display: flex;
    justify-content: space-between;
    height: 70px;
    margin-bottom: 20px;
    align-items: center;
    color: var(main-text-color);
    @media (min-width: 1440px) {
      padding: 0 0 0 40px;
    }
    .title {
      font-size: 20px;
      font-weight: 900;
      width: 180px;
      @media (min-width: 768px) {
        width: 200px;
      }
      @media (min-width: 1580px) {
        font-size: 26px;
        width: 400px;
      }
    }
    .back {
      background: url("../../assets/icons/casino-header.webp") no-repeat;
      height: 90px;
      width: 220px;
      background-size: auto 90px;
      &.live {
        background: url("../../assets/icons/casino-header-live.webp") no-repeat 10px -65px/400px;
        height: 70px;
        width: 400px;
      }
    }
  }
  .parent-buttons {
    display: flex;
    margin: 0 auto 10px;
    width: 305px;
    justify-content: space-between;
    @media (min-width: 408px) {
      width: 388px;
    }
    @media (min-width: 627px) {
      width: 587px;
    }
    @media (min-width: 768px) {
      width: 728px;
    }
    @media (min-width: 954px) {
      width: 914px;
    }
    @media (min-width: 1024px) {
      grid-area: search;
      width: auto;
    }

    .filter-toggle-button {
      height: 40px;
      padding-left: 30px;
      border-radius: 20px;
      background: url(../../assets/icons/filter.svg) no-repeat;
      background-color: var(main-explosive);
      background-size: 22px;
      line-height: 40px;
      color: var(main-text-color);
      background-position: calc(100% - 11px) center;
      width: 148px;
      font-size: 16px;
      font-weight: 700;
      margin-right: 9px;
      @media (min-width: 408px) {
        width: 189px;
      }
      @media (min-width: 768px) {
        width: 175px;
      }
      @media (min-width: 1024px) {
        display: none;
      }
    }
    .search-filter {
      position: relative;
      width: 148px;
      @media (min-width: 408px) {
        width: 189px;
      }
      @media (min-width: 768px) {
        width: 175px;
      }
      @media (min-width: 1024px) {
        width: 155px;
      }
      @media (min-width: 1440px) {
        width: 166px;
      }
      @media (min-width: 1580px) {
        width: 189px;
      }
      input {
        width: 100%;
        height: 40px;
        font-size: 10px;
        color: var(main-text-color);
        border-radius: 20px;
        background-color: var(main-gray);
        margin-bottom: 10px;
        padding-left: 10px;
        border: 0;
        outline: 0;
        font-size: 16px;
        @media (min-width: 1024px) {
          font-size: 11px;
          height: 30px;
        }

        @media (min-width: 1580px) {
          font-size: 16px;
          height: 40px;
        }
        &::-webkit-input-placeholder {
          color: var(main-text-color);
          opacity: 0.8;
        }
        &::-ms-input-placeholder {
          color: var(main-text-color);
          opacity: 0.8;
        }
        &::placeholder {
          color: var(main-text-color);
          opacity: 0.8;
        }
      }
      .search-icon {
        position: absolute;
        right: 0;
        background-color: var(main-explosive);
        top: 0;
        height: 40px;
        width: 40px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (min-width: 1024px) {
          height: 30px;
          width: 30px;
        }
        @media (min-width: 1580px) {
          height: 40px;
          width: 40px;
        }
        &::after {
          content: "";
          background-size: 21px;
          -webkit-mask-size: 21px;
          mask-size: 21px;
          -webkit-mask-image: url(../../assets/icons/search-icon.svg);
          mask-image: url(../../assets/icons/search-icon.svg);
          background-color: var(main-text-color);
          background-position: center;
          mask-position: center;
          webkit-mask-position: center;
          width: 21px;
          height: 21px;
          @media (min-width: 1024px) {
            width: 16px;
            height: 16px;
            background-size: 16px;
            -webkit-mask-size: 16px;
            mask-size: 16px;
          }
        }
      }
    }
  }
  .games-list {
    padding: 10px;
    overflow-y: auto;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 325px;
    grid-column-gap: 9px;
    grid-row-gap: 10px;
    @media (min-width: 408px) {
      width: 408px;
    }
    @media (min-width: 627px) {
      width: 627px;
      grid-template-columns: repeat(3, 1fr);
      padding-left: 20px;
      padding-right: 20px;
    }
    @media (min-width: 768px) {
      width: 768px;
      grid-template-columns: repeat(4, 1fr);
    }
    @media (min-width: 954px) {
      width: 954px;
      grid-template-columns: repeat(5, 1fr);
    }
    @media (min-width: 1024px) {
      width: 1024px;
      grid-template-columns: repeat(6, 1fr);
    }
    @media (min-width: 1190px) {
      width: 1190px;
      grid-template-columns: repeat(7, 1fr);
    }
    @media (min-width: 1354px) {
      width: 1354px;
      grid-template-columns: repeat(8, 1fr);
    }
    @media (min-width: 1440px) {
      width: 1440px;
      grid-template-columns: repeat(8, 1fr);
    }
    @media (min-width: 1580px) {
      width: 1580px;
      padding-left: 100px;
      padding-right: 100px;
      grid-template-columns: repeat(7, 1fr);
    }
    @media (min-width: 1620px) {
      padding-left: 20px;
      padding-right: 20px;
      width: 1620px;
      grid-template-columns: repeat(8, 1fr);
    }
    .game {
      position: relative;
      width: 148px;
      height: 150px;
      background-color: var(dark);
      @media (min-width: 408px) {
        width: 189px;
        height: 180px;
      }
      @media (min-width: 768px) {
        width: 175px;
        height: 180px;
      }
      @media (min-width: 1024px) {
        width: 155px;
        height: 160px;
      }
      @media (min-width: 1440px) {
        width: 166px;
        height: 170px;
      }
      @media (min-width: 1580px) {
        width: 189px;
        height: 180px;
      }
      .image-wrapper {
        position: relative;
        width: 100%;
        height: 100px;
        overflow: hidden;
        @media (min-width: 408px) {
          height: 120px;
        }
        @media (min-width: 1024px) {
          height: 110px;
        }
        @media (min-width: 1440px) {
          height: 120px;
        }
        @media (min-width: 1580px) {
          height: 120px;
        }
      }
      img {
        width: 100%;
      }
      .play-hover-button {
        display: none;
        color: var(main-text-color);
        cursor: pointer;
        z-index: 3;
        &::before {
          border-left: 8px solid var(main-explosive);
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-right: 4px solid transparent;
          content: " ";
          display: inline-block;
        }
      }
      .bonus-wrapper {
        position: absolute;
        top: -10px;
        left: 0;
        height: 20px;
        width: 100%;
        z-index: 6;
        .text {
          z-index: 2;
          position: absolute;
          left: 0;
          text-align: center;
          width: 100%;
          font-size: 8px;
          font-weight: 700;
          top: 7px;
          @media (min-width: 408px) {
            top: 12px;
          }
          @media (min-width: 1024px) {
            top: 7px;
          }
          @media (min-width: 1580px) {
            top: 12px;
          }
        }
        .game-bonus {
          position: absolute;
          top: -10px;
          left: 0;
        }
      }
      .favorite-wrapper {
        height: 30px;
        width: 30px;
        background-color: var(main-gray-opacity);
        right: 0;
        bottom: 0;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        z-index: 4;
        mix-blend-mode: multiply;
        &.added {
          mix-blend-mode: normal;
        }
        @media (min-width: 1024px) {
          mix-blend-mode: normal;
          display: none;
          &.added {
            display: flex;
            mix-blend-mode: normal;
          }
        }
        .fav-btn {
          width: 22px;
          height: 22px;
          background: var(main-text-color);
          -webkit-mask-image: url(../../assets/icons/star.svg);
          -webkit-mask-size: 22px;
          cursor: pointer;
          @media (min-width: 1024px) {
            -webkit-mask-image: none;
            background: url(../../assets/icons/star.svg) no-repeat;
            background-size: 22px;
          }

          &.remove {
            background: var(main-explosive) !important;
            -webkit-mask-image: url(../../assets/icons/star.svg) !important;
            -webkit-mask-size: 22px !important;
          }
        }
      }
      .game-info {
        padding-top: 7px;
        padding-bottom: 9px;
        padding-left: 7px;
        text-transform: uppercase;
        position: relative;
        height: 50px;
        z-index: 1;
        cursor: pointer;
        @media (min-width: 408px) {
          height: 60px;
        }
        @media (min-width: 1024px) {
          height: 50px;
        }
        @media (min-width: 1580px) {
          height: 60px;
        }
        .name {
          font-size: 14px;
          font-weight: 600;
          color: var(main-text-color);
          overflow: hidden;
          white-space: pre-wrap;
          max-height: 28px;
          line-height: 14px;
          text-overflow: ellipsis;
          margin-bottom: 1px;
          @media (min-width: 408px) {
            font-size: 16px;
            max-height: 32px;
            line-height: 16px;
          }
          @media (min-width: 1024px) {
            font-size: 14px;
            max-height: 28px;
            line-height: 14px;
          }
          @media (min-width: 1440px) {
            font-size: 15px;
            max-height: 30px;
            line-height: 15px;
          }
          @media (min-width: 1580px) {
            font-size: 16px;
            max-height: 32px;
            line-height: 16px;
          }
        }
        .provider {
          font-size: 10px;
          color: var(main-color);
          @media (min-width: 408px) {
            font-size: 12px;
          }
          @media (min-width: 1024px) {
            font-size: 10px;
          }
          @media (min-width: 1440) {
            font-size: 11px;
          }
          @media (min-width: 1580px) {
            font-size: 12px;
          }
        }
      }
      .hovered-back {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(main-dark-explosive);
        height: 50px;
        z-index: 0;
        @media (min-width: 408px) {
          height: 60px;
        }
        @media (min-width: 1024px) {
          height: 50px;
        }
        @media (min-width: 1580px) {
          height: 60px;
        }
        //.game:hover;
      }
      &:hover {
        @media (min-width: 1024px) {
          .hovered-back {
            display: block;
          }
          .play-hover-button {
            display: inline-block;
            position: absolute;
            top: calc(50% - 6px);
            left: calc(50% - 33px);
          }
          .image-wrapper::after {
            background-color: #07020e;
            content: " ";
            z-index: 2;
            opacity: 0.88;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
          .favorite-wrapper {
            display: flex;
            .fav-btn {
              background: var(main-text-color);
              -webkit-mask-image: url(../../assets/icons/star.svg);
              -webkit-mask-size: 22px;
            }
          }
        }
      }
    }
  }
}
.game-window {
  width: 100%;
  height: calc(100% - 30px);
  border: 0;
  position: absolute;
  top: 30px;
  @media (min-width: 1024px) {
    // top: 66px;
    height: calc(100% - 66px);
  }
}
.casino-loading-ref {
  height: 30px;
  // margin: 0px 15px 100px 15px
  margin: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.casino-game-wrapper-test-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1rem 1.5rem;
  width: 24rem;
  border-radius: 0.5rem;
}

.casino-game-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  z-index: 100;
  .close-icon {
    width: 24px;
    height: 24px;
    border-radius: 20px;
    border: solid 1px #863cc4;
    display: inline-block;
    color: #fff;
    line-height: 25px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    font-weight: 800;
    position: absolute;
    top: 3px;
    right: 9px;
    background-color: var(main-color);
    z-index: 1;
    @media (min-width: 1024px) {
      width: 40px;
      height: 40px;
      line-height: 38px;
      font-size: 25px;
      top: 40px;
      right: 10px;
    }
  }
  .fullscreen-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 3px;
    padding: 1px 3px;
    right: 50px;
    cursor: pointer;
    border-radius: 20px;
    border: solid 1px #863cc4;
    z-index: 1;
    background-color: var(main-color);
    @media (min-width: 1024px) {
      top: 40px;
      right: 60px;
      width: 40px;
      padding: 6px;
      height: 40px;
    }
    img {
      width: 16px;
      height: 16px;
      @media (min-width: 1024px) {
        width: 25px;
        height: 25px;
      }
    }
  }
}
.casino-filter-wrapper {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  transition: visibility 0s, opacity 0.75s linear;
  justify-content: space-evenly;
  color: #62687b;
  width: 305px;
  @media (min-width: 408px) {
    width: 388px;
  }
  @media (min-width: 627px) {
    width: 587px;
  }
  @media (min-width: 768px) {
    width: 728px;
  }
  @media (min-width: 954px) {
    width: 914px;
  }
  @media (min-width: 1024px) {
    width: 984px;
    display: grid;
    justify-content: space-between;
    grid-template-areas: "filter search" "sFilter sFilter";
    grid-template-columns: 1fr 155px;
  }
  @media (min-width: 1190px) {
    width: 1150px;
  }
  @media (min-width: 1354px) {
    width: 1314px;
  }
  @media (min-width: 1440px) {
    width: 1400px;
    grid-template-columns: 1fr 166px;
  }
  @media (min-width: 1580px) {
    width: 1380px;
    grid-template-columns: 1fr 189px;
  }
  @media (min-width: 1620px) {
    width: 1580px;
  }

  & button {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 5px 10px;
    border: solid 0.7px #323645;
    border-radius: 12.5px;
    outline: none;
    margin-left: 10px;
    margin-top: 10px;

    &:hover {
      background-color: #323645;
    }
    &:active {
      background-color: #323645b6;
    }
  }
  .casino-fltr-btn {
    background-color: transparent;
    height: 25px;

    & img {
      height: 100%;
      filter: grayscale(100%);
    }
  }
  .provider-filter,
  .category-filter {
    background-color: var(dark);
    height: 25px;
  }
}
.provider-filters {
  display: flex;
}
.category-filters {
  display: flex;
}
.selected-filter {
  background-color: var(main-explosive-opacity) !important;
  font-weight: 700;
}
.game-filters-collapse {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  margin: 0 auto;
  @media (min-width: 627px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: 1024px) {
    max-width: 100%;
  }
  & > p {
    background: transparent;
    color: #b1b4bc;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    border: none;
    margin: 15px 0;
  }
  & > p > img {
    width: 18px;
  }
  .collapse-btns:focus {
    border: none;
    outline: none;
  }
  .collapse-btns {
    background: var(main-color);
    border: none;
    width: 100%;
    margin: 5px 0;
    & > img {
      width: 18px;
    }
  }
  .selected-filters {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
    div {
      cursor: pointer;
      height: 40px;
      font-size: 14px;
      line-height: 38px;
      border-radius: 20px;
      background-color: var(main-explosive-opacity);
      text-align: center;
      border: 1px solid var(main-explosive);
      min-width: 100%;
      margin-bottom: 10px;
      color: var(main-text-color);
      font-weight: 700;
      position: relative;
      .cross {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 16px;
        font-size: 16px;
        color: var(main-text-color);
      }
      @media (min-width: 1024px) {
        font-size: 11px;
        height: 30px;
        border-radius: 15px;
        line-height: 30px;
        min-width: calc((100% / 7) - (50px / 6));
        margin-right: 10px;
      }
    }
  }
}
.category-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 0;
  transition: height 0.5s;
  overflow: hidden;
  &.visible {
    height: 150px;
    @media (min-width: 627px) {
      height: 100px;
    }
    @media (min-width: 954px) {
      height: 50px;
    }
  }
  @media (min-width: 1024px) {
    height: auto !important;
    grid-area: filter;
    justify-content: flex-start;
  }
  .category-filter {
    height: 40px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 40px;
    border-radius: 20px;
    background-color: var(main-gray);
    text-align: center;
    margin-bottom: 10px;
    width: 148px;
    font-weight: 400;
    &:nth-child(2n-1) {
      margin-right: 9px;
    }
    &:last-child {
      margin-right: 0;
    }
    @media (min-width: 408px) {
      width: 189px;
    }
    @media (min-width: 627px) {
      margin-right: 9px;
      &:nth-child(3n) {
        margin-right: 0px;
      }
    }
    @media (min-width: 768px) {
      width: 175px;
      &:nth-child(3n) {
        margin-right: 9px;
      }
      &:nth-child(4n) {
        margin-right: 0px;
      }
      &:nth-child(5n) {
        margin-right: 0px;
      }
    }
    @media (min-width: 954px) {
      &:nth-child(4n) {
        margin-right: 9px;
      }
    }
    @media (min-width: 1024px) {
      width: 155px;
      font-size: 11px;
      height: 30px;
      line-height: 30px;
      &:last-child {
        margin-right: 0 !important;
      }
    }
    @media (min-width: 1440px) {
      width: 166px;
    }
    @media (min-width: 1580px) {
      width: 189px;
      font-size: 16px;
      height: 40px;
      line-height: 40px;
      @media (min-width: 1024px) {
        margin-right: 10px;
      }
    }
    a {
      text-decoration: none;
      text-align: center;
      font-weight: 400;
      color: var(main-text-color) !important;
      display: inline-block;
      -webkit-appearance: none;
    }
  }
  .category-filter-active,
  .category-filter:hover {
    border: 1px solid var(main-explosive) !important;
    line-height: 38px;
    @media (min-width: 1024px) {
      line-height: 28px;
    }
    @media (min-width: 1580px) {
      line-height: 38px;
    }
    a {
      font-weight: 600;
    }
  }
}
.clear-all {
  width: 189px;
  margin: 0 auto;
  height: 40px;
  border-radius: 20px;
  background-color: var(main-explosive) !important;
  color: var(main-text-color);
  line-height: 40px;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.category-sub-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  @media (max-width: 320px) {
    justify-content: unset;
  }
  @media (min-width: 1024px) {
    grid-area: sFilter;
  }
  .category-sub-filter {
    cursor: pointer;
    height: 40px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: var(dark);
    text-align: center;
    border: 1px solid var(main-explosive);
    width: 148px;
    margin-right: 9px;
    margin-bottom: 10px;
    color: var(main-text-color);
    &:nth-child(2n) {
      margin-right: 0;
    }
    &:hover {
      font-weight: 700;
      background-color: var(main-explosive-opacity);
    }
    @media (min-width: 408px) {
      width: 189px;
    }
    @media (min-width: 627px) {
      &:nth-child(2n) {
        margin-right: 9px;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    @media (min-width: 768px) {
      width: 175px;
      &:nth-child(3n) {
        margin-right: 9px;
      }
      &:nth-child(4n) {
        margin-right: 0px;
      }
    }
    @media (min-width: 954px) {
      &:nth-child(4n) {
        margin-right: 9px;
      }
      &:nth-child(5n) {
        margin-right: 0px;
      }
    }
    @media (min-width: 1024px) {
      font-size: 11px;
      height: 30px;
      width: 155px;
      &:nth-child(5n) {
        margin-right: 9px;
      }
      &:nth-child(6n) {
        margin-right: 0px;
      }
    }
    @media (min-width: 1190px) {
      &:nth-child(6n) {
        margin-right: 9px;
      }
      &:nth-child(7n) {
        margin-right: 0px;
      }
    }
    @media (min-width: 1354px) {
      &:nth-child(7n) {
        margin-right: 9px;
      }
      &:nth-child(8n) {
        margin-right: 0px;
      }
    }
    @media (min-width: 1440px) {
      width: 166px;
    }
    @media (min-width: 1580px) {
      width: 189px;
      height: 40px;
      font-size: 14px;
      &:nth-child(8n) {
        margin-right: 9px;
      }
      &:nth-child(7n) {
        margin-right: 0px;
      }
    }
    @media (min-width: 1620px) {
      &:nth-child(7n) {
        margin-right: 9px;
      }
      &:nth-child(8n) {
        margin-right: 0px;
      }
    }
  }
  .category-sub-footer {
    display: flex;
    width: 148px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 20px;
    background-color: var(main-explosive);
    color: var(main-text-color);
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    @media (min-width: 408px) {
      width: 189px;
    }
    @media (min-width: 768px) {
      width: 175px;
    }
    @media (min-width: 1024px) {
      width: 155px;
      font-size: 14px;
      line-height: 30px;
      height: 30px;
    }
    @media (min-width: 1440px) {
      width: 166px;
    }
    @media (min-width: 1580px) {
      width: 189px;
      font-size: 16px;
      line-height: 40px;
      height: 40px;
    }
  }
  .category-content {
    color: #b1b4bc;
    padding: 10px 0px;
    flex-grow: 1;
    @media (max-width: 768px) {
      padding: 10px 15px;
    }
    & > div > h2 {
      font-size: 27px;
      font-weight: 700;
      line-height: 29px;
      color: var(main-text-color);
      margin-bottom: 11px;
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
    & > div > a {
      color: var(main-color);
      font-size: 11px;
    }
  }
}
.search-result {
  position: relative;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  color: var(main-text-color);
  @media (min-width: 1024px) {
    font-size: 13px;
    align-self: start;
    margin-left: calc((100% - 984px) / 2);
  }
  @media (min-width: 1190px) {
    margin-left: calc((100% - 1150px) / 2);
  }
  @media (min-width: 1354px) {
    margin-left: calc((100% - 1314px) / 2);
  }
  @media (min-width: 1440px) {
    margin-left: calc((100% - 1400px) / 2);
  }
  @media (min-width: 1580px) {
    margin-left: calc((100% - 1380px) / 2);
  }
  @media (min-width: 1620px) {
    margin-left: calc((100% - 1580px) / 2);
  }
  span {
    font-weight: 700;
  }
  .cross {
    margin-left: 30px;
    cursor: pointer;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
}
.close-btn:focus {
  outline: none;
}
.close-btn {
  background-color: transparent;
  border: none;
  position: relative;
  top: -175px;
  right: -15px;
  img {
    width: 20px;
    height: 20px;
  }
}
.modal-main {
  position: fixed;
  text-align: center;
  width: 275px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (min-width: 428px) {
    width: 328px;
  }
  @media (min-width: 1024px) {
    width: 500px;
  }
  .game {
    position: relative;
    width: 100%;
    line-height: 26px;
    font-size: 27px;
    color: var(main-text-color);
    .close-btn {
      top: -62px;
      position: absolute;
      right: 0;
      @media (min-width: 1024px) {
        top: -48px;
        right: -48px;
      }
    }
    .big-image {
      width: 100%;
    }
    .you-are-lucky {
      margin-top: 34px;
      margin-bottom: 10px;
      font-weight: 700;
    }
    .game-name {
      text-transform: uppercase;
      margin-bottom: 30px;
    }
    .game-play-btn,
    .game-try-again {
      width: 135px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      margin: 0 auto;
      border-radius: 20px;
      cursor: pointer;
      @media (min-width: 1024px) {
        display: inline-flex;
      }
    }
    .game-play-btn {
      background-color: var(main-explosive);
      font-weight: 700;
      margin-bottom: 20px;
      @media (min-width: 1024px) {
        margin-right: 10px;
      }
    }
    .game-try-again {
      border: 1px solid var(main-explosive);
      background-color: var(dark);
    }
  }
}
.display-block {
  visibility: visible;
  opacity: 1;
  display: block !important;
}
.display-none {
  visibility: hidden;
  height: 0px;
  opacity: 0;
}
.casino-notfound-game {
  text-align: center;
  color: #b1b4bc;
  font-size: 30px;
  padding: 50px;
}
.casino-notfound-bonuses {
  text-align: center;
  color: #b1b4bc;
  font-size: 20px;
}
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
} ;
