.profileSettings-wrapper {
    margin: 50px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .inputBoxArea {
        width: 100%;
        position: relative;
    }
    &--header {
        color: #171832;
        font-size: 24px;
        font-weight: bold;
        display: block;
        margin-bottom: 25px;
    }
    &--passwordArea {
        width: 100%;
        display: flex;
        justify-content: space-between;
        @media(max-width: 992px) {
            flex-direction: column;
        }
        &--newPassword {
            width: 49%;
            @media(max-width: 992px) {
            width: 100%;
        }
        }
        &--oldPassword {
            width: 49%;
            @media(max-width: 992px) {
            width: 100%;
        }
        }
    }
}
