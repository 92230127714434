.selectETwrapper {
    margin-bottom: 20px;
    .custom-select {
        position: relative;
        display: inline-block;
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 10px; /* Sağ tarafa kaydırma miktarını ayarlayın */
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 4px 0 4px;
            border-color: #000 transparent transparent transparent;
            pointer-events: none;
        }
    }
    &__label {
        font-family: "Poppins";
        margin-bottom: 10px;
        text-align: start;
        font-size: 14.5px;
        margin-left: 5px;
    }
    select {
        width: 100%;
        height: 45px;
        border: 1px solid #ced4da;
        border-radius: 5px;
        padding: 10px;
        font-size: 14px;
    }
}