.customAccordions {
  &--header {
    height: 40px;
    background-image: linear-gradient(84deg, #fe6740 0%, #f29626 100%);
    padding: 0 20px;
    color: #fff;
  }
  table {
    font-size: 12px;
    width: 100%;
    tr {
      th {
        &:first-child {
          padding-left: 10px;
          width: 15%;
        }
        &:nth-child(2) {
          width: 25%;
        }
        &:nth-child(3) {
          width: 7.5%;
          text-align: center;
        }
        &:nth-child(4) {
          width: 7.5%;
          text-align: center;
        }
        &:nth-child(5) {
          width: 7.5%;
          text-align: center;
        }
        &:nth-child(6) {
          width: 7.5%;
          text-align: center;
        }
        &:nth-child(7) {
          width: 7.5%;
          text-align: center;
        }
        &:nth-child(8) {
          width: 7.5%;
          text-align: center;
        }
        &:nth-child(9) {
          width: 7.5%;
          text-align: center;
        }
        &:nth-child(10) {
          width: 7.5%;
          text-align: center;
        }
      }
    }
  }
  &--tableItem {
    color: #333645;
    img {
      border-radius: 0 !important;
    }
    td {
      padding: 10px 0;
      &:first-child {
        display: flex;
        flex-direction: column;
        width: 15%;
        height: 100%;
        margin-top: 5px;
        padding-left: 10px;
      }
      &:nth-child(2) {
        width: 25%;
      }
      &:nth-child(3) {
        width: 7.5%;
        text-align: center;
      }
      &:nth-child(4) {
        width: 7.5%;
        text-align: center;
      }
      &:nth-child(5) {
        width: 7.5%;
      }
      &:nth-child(6) {
        width: 7.5%;
        text-align: center;
      }
      &:nth-child(7) {
        width: 7.5%;
        text-align: center;
      }
      &:nth-child(8) {
        width: 7.5%;
        text-align: center;
      }
      &:nth-child(9) {
        width: 7.5%;
        text-align: center;
      }
      &:nth-child(10) {
        width: 7.5%;
        text-align: center;
      }
    }
    &--ratio {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 37px;
      height: 26px;
      border-radius: 5px;
      border: solid 0.5px #000;
    }
  }
}

.matchinfoAccordionBody {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  &--result {
    padding: 15px;
    width: 100%;
    height: 130px;
    background-image: linear-gradient(84deg, #fe6740 0%, #f29626 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    &--left {
      display: flex;
      flex-direction: column;
      span {
        &:first-child {
          font-size: 14px;
          font-weight: normal;
        }
        &:last-child {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    &--mid {
      display: flex;
      span {
        width: 45px;
        height: 60px;
        border-radius: 5px;
        background-color: #333645;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: bold;
        &:nth-child(2) {
          font-size: 16px;
          font-weight: normal;
          width: 45px;
          height: 60px;
          background-color: transparent;
        }
      }
    }
    &--right {
      display: flex;
      flex-direction: column;
      span {
        &:first-child {
          font-size: 14px;
          font-weight: normal;
          text-align: right;
        }
        &:last-child {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
  &--info {
    &--line {
      display: flex;
      align-items: center;
      padding: 0 15px;
      height: 30px;
      background-color: #333645;
      color: #fff;
      font-size: 14px;
      font-weight: normal;
      border-bottom: 0.5px solid #1f2230;
      div {
        margin-left: auto;
        display: flex;
        img {
          max-width: 18px;
        }
        span {
          width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 35px;
        }
      }
    }
  }
}
