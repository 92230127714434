.withdrawOptionsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 534px) {
        justify-content: center;
    }
    @media (min-width: 768px) and (max-width: 992px) {
        justify-content: space-around;
    }
    &--header {
        width: 100%;
        color: #171832;
        font-weight: bold;
        &--titles {
            font-size: 24px;
            display: flex;
            justify-content: space-between;
            @media (max-width: 992px) {
                margin-bottom: 20px;
            }
        }
        &--table-titles {
            display: flex;
            justify-content: space-between;
            padding: 0 20px;
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: 16px;
            &--left {
                width: 50%;
                display: flex;
                justify-content: space-between;
                span {
                    &:nth-child(2) {
                        margin-left: 20px;
                    }
                    &:last-child {
                        margin-right: 55px;
                    }
                }
            }
            &--right {
                margin-right: 120px;
            }
            @media (max-width: 992px) {
                display: none;
            }
        }
    }
}

.withdrawOptionBox {
    width: 220px;
    height: 300px;
    min-height: 300px;
    padding: 15px 20px;
    border-radius: 10px;
    border: solid 1px #000;
    cursor: default;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    &--left {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 100%;
        &--img {
            min-height: 55px;
            max-height: 55px;
            img {
                min-width: 140px;
                max-width: 140px;
            }
        }
        &--minMax {
            margin-top: 10px;
            color: #171832;
            font-size: 22px;
            font-weight: normal;
            letter-spacing: normal;
            &--cont {
                text-align: left;
            }
            @media (max-width: 1024px) {
                font-size: 18px;
            }
            @media (max-width: 500px) {
                font-size: 14px;
            }
        }
        @media (max-width: 1024px) {
            width: 100%;
            img {
                max-width: 115px;
            }
        }
    }
    &--right {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        height: 100%;
        &--promotion {
            &::-webkit-scrollbar {
                display: none !important;
            }
            width: 100%;
            max-height: 90px;
            min-height: 30px;
            overflow: scroll;
            // padding-top: 30px;
            background-image: linear-gradient(84deg, #863cc4 0%, #1631ba 100%) !important;
            font-size: 10px;
            color: #fff;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        &--selectBox {
            width: 100%;
            background-image: linear-gradient(87deg, #f29626 0%, #fe6740 100%);
            font-size: 18px;
            color: #fff;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;
            @media (max-width: 1024px) {
                font-size: 14px;
            }
        }
        @media (max-width: 1024px) {
            width: 100%;
        }
    }
    @media (max-width: 1024px) {
        flex-direction: column;
        height: 140px;
        justify-content: space-between;
    }
}

.withdraw-error-popup {
    &__title {
        font-size: 25px;
        font-weight: bold;
        color: #171832;
        margin-bottom: 25px;
    }
    &__description {
        color: #171832;
        font-size: 15px;
    }
    &__btn {
        margin-top: 25px;
        button {
            margin-left: 0;
        }
    }
}
