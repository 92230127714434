.gameCard {
    min-width: 202.8px;
    min-height: 250.7px;
    object-fit: contain;
    overflow: hidden;
    margin-right: 16.7px;
    margin-bottom: 20px;
    border-radius: 10px;
    
    &:last-child {
        margin-right: 0;
    }
    img {
        border-radius: 10px;
    }
    &:hover {
        background-color: #2232bb !important;
        .gameCard-content {
            display: flex !important;
        }   
        img {
            &:hover {
                transition: 0.6s;
                mix-blend-mode: soft-light;
            }
        }
    }
}

.gameCard-content {
    display: none;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    padding-bottom: 24.5px;
    z-index: 10;
    margin-top: -149px;
    &--game-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        span {
            &:first-child {
                font-size: 18px;
                font-weight: 800;
            }
            &:last-child {
                margin-bottom: 42.4px;
                font-size: 14px;
                font-weight: normal;
            }
        } 
    }
}

.gameCard-btn {
    width: 121.9px;
    height: 34.2px;
    border: 1px solid #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
}