.profileSettings-wrapper {
    margin: 50px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .inputBoxArea {
        width: 100%;
    }
    &--header {
        color: #171832;
        font-size: 24px;
        font-weight: bold;
        display: block;
        margin-bottom: 25px;
    }
    &--passwordArea {
        width: 100%;
        display: flex;
        justify-content: space-between;
        @media (max-width: 992px) {
            flex-direction: column;
        }
        &--newPassword {
            width: 49%;
            @media (max-width: 992px) {
                width: 100%;
            }
        }
        &--oldPassword {
            width: 49%;
            @media (max-width: 992px) {
                width: 100%;
            }
        }
    }
    &--dropdown-wrapper {
        margin-bottom: 30px;
        .dropdown {
            .btn-primary {
                background-color: transparent !important;
                border-color: transparent !important;
            }
            button {
                background-image: linear-gradient(84deg, #863cc4 0%, #1631ba 100%) !important;
                min-width: 300px;
                text-align: start;
                display: flex;
                align-items: center;
                &::after {
                    margin-left: auto;
                }
            }
        }
        .dropdown-menu {
            min-width: 300px;
            .dropdown-item {
                &:active {
                    background-color: transparent;
                    background-image: linear-gradient(84deg, #863cc4 0%, #1631ba 100%) !important
                }
            }
        }
    }
}
