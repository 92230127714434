.navItemWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  width: 120px;
  .navItem {
    width: 100%;
    font-weight: normal;
    color: #fff;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    .navItemIcons {
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 4.6px;
      &--icon-sports {
        width: 32px;
        height: 32px;
        background-image: url(../../../assets/icons/sports.svg);
      }
      &--icon-live {
        width: 37.6px;
        height: 30.8px;
        background-image: url(../../../assets/icons/live.svg);
      }
      &--icon-virtual {
        width: 47.8px;
        height: 30.8px;
        background-image: url(../../../assets/icons/virtual.svg);
      }
      &--icon-poker {
        width: 34.2px;
        height: 30.8px;
        background-image: url(../../../assets/icons/poker.svg);
      }
      &--icon-casino {
        width: 30.8px;
        height: 30.8px;
        background-image: url(../../../assets/icons/casino.svg);
      }
      &--icon-slots {
        width: 41px;
        height: 30.8px;
        background-image: url(../../../assets/icons/slots.svg);
      }
      &--icon-other {
        width: 41px;
        height: 30.8px;
        background-image: url(../../../assets/icons/others.svg);
      }
    }
    span {
      line-height: normal !important;
    }
    &:hover {
      color: #fe8d26 !important;
      .navItemIcons {
        &--icon-sports {
          background-image: url(../../../assets/icons/sports-orange.svg) !important;
        }
        &--icon-live {
          background-image: url(../../../assets/icons/live-orange.svg) !important;
        }
        &--icon-virtual {
          background-image: url(../../../assets/icons/virtual-orange.svg) !important;
        }
        &--icon-poker {
          background-image: url(../../../assets/icons/poker-orange.svg) !important;
        }
        &--icon-casino {
          background-image: url(../../../assets/icons/casino-orange.svg) !important;
        }
        &--icon-slots {
          background-image: url(../../../assets/icons/slots-orange.svg) !important;
        }
        &--icon-other {
          background-image: url(../../../assets/icons/others-orange.svg) !important;
        }
      }
    }
  }
  .navItem-line {
    position: absolute;
    right: 0;
    width: 1px;
    height: 52px;
    border-right: 1px solid rgba(255, 255, 255, 0.15);
  }
  // @media(max-width: 412px )  {
  //   width: 82px !important;
  // }
}
