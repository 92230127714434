.topCards {
    margin-top: 17px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    img {
        width: 69%;
        border-radius: 10px;
        max-width: 970px;
        max-height: 385px;
    }
    &--right {
        width: 29%;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        justify-content: space-between;
        img {
            width: 100%;
            height: 48%;
            max-width: 312.2px !important;
            max-height: 185px !important;
        }
        @media(max-width: 770px) {
            width: 100%;
            flex-direction: row;
            img {
                max-width: 48% !important;
            }
        }
    }
    @media(max-width: 770px) {
        flex-direction: column;
        img {
            width: 100%;
            margin-bottom: 15px;
        }
    }
}