.walletbox {
    width: 315px;
    height: 135px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    img {
        margin-right: 30px;
        width: 64px;
        height: 64px;
        margin-left: 40px;
    }
    &--title-amount {
        display: flex;
        flex-direction: column;
        span {
            &:first-child {
                margin-bottom: 10px;
                font-size: 16px;
                font-weight: bold;
                color: #fff;
                @media(max-width: 768px) {
                    font-size: 13px;
                    margin-bottom: 5px;
                }
            }
            &:last-child {
                font-size: 22px;
                font-weight: bold;
                color: #fff;
                @media(max-width: 768px) {
                    font-size: 14px;
                }
            }
        }
    }
    @media(max-width: 768px) {
        width: 32%;
        height: 100px;
        justify-content: center;
        img {
            margin-left: 10px;
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }
    }
    @media(max-width: 500px) {
        img {
            display: none;
        }
    }
    @media(max-width: 992px) {
        width: 32%;
        justify-content: space-between;
        padding: 0 10px;
        img {
            margin-left: 0;
            margin-right: 10px;
        }
    }
    @media(max-width: 1400px) {
        width: 32%;
        justify-content: space-between;
        padding: 0 15px;
        img {
            margin-left: 0;
            margin-right: 10px;
        }
    }
}
.settingsBox {
    cursor: pointer;
    width: 305px;
    height: 80px;
    border: solid 1px #171832;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-right: 15px;
    img {
        margin-right: 5px;
    }
    span {
        font-size: 15px;
        font-weight: normal;
        color: #171832;
        margin-bottom: 0 !important;
    }
    @media(max-width: 768px) {
        width: 243px;
    }
    @media(max-width: 540px) {
        width: 100%;
    }
}