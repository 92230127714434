.accordionType1 {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333645;
  font-weight: normal;
  &--line {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    &--section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-right: 0.5px solid rgba(51, 54, 69, 0.1);
      border-bottom: 0.5px solid rgba(51, 54, 69, 0.1);
      width: calc(100% / 3);
      height: 100%;
      padding: 0 25px;
      &:first-child {
        border-left: 0.5px solid rgba(51, 54, 69, 0.1);
      }
      span {
        &:first-child {
          color: rgba(51, 54, 69, 0.5);
        }
        &:last-child {
          font-weight: bold;
          color: rgba(51, 54, 69, 1);
          @media(max-width: 992px) {
            font-size: 13px;
            text-align: end;
          }
        }
      }
      @media(max-width: 992px) {
        padding: 0 5px;
      }
    }
  }
}

.accordionType2 {
  width: 100%;
  align-items: center;
  font-size: 14px;
  color: #333645;
  font-weight: normal;
  &--line {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    &--section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-right: 0.5px solid rgba(51, 54, 69, 0.1);
      border-bottom: 0.5px solid rgba(51, 54, 69, 0.1);
      width: 50%;
      height: 100%;
      padding: 0 25px;
      &--team {
        font-weight: bold;
        color: rgba(51, 54, 69, 1) !important;
      }
      &:first-child {
        border-left: 0.5px solid rgba(51, 54, 69, 0.1);
      }
      span {
        color: rgba(51, 54, 69, 0.5);
        &:first-child {
          margin-right: 20px;
        }
        @media(max-width: 992px) {
          font-size: 13px;
        }
      }
      @media(max-width: 992px) {
        padding: 0 5px;
      }
    }
  }
}

.accordionType3 {
  width: 100%;
  align-items: center;
  font-size: 14px;
  color: #333645;
  font-weight: normal;
  &--line {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    &--section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-right: 0.5px solid rgba(51, 54, 69, 0.1);
      width: calc(100% / 3);
      height: 100%;
      padding: 0 25px;
      &--team {
        font-weight: bold;
        color: rgba(51, 54, 69, 1) !important;
      }
      &:first-child {
        border-left: 0.5px solid rgba(51, 54, 69, 0.1);
      }
      span {
        color: rgba(51, 54, 69, 0.5);
        &:last-child {
          font-weight: bold;
          color: rgba(51, 54, 69, 1) !important;
        }
      }
    }
  }
}

.accordionType4 {
  width: 100%;
  align-items: center;
  font-size: 14px;
  color: #333645;
  font-weight: normal;
  &--line {
    width: 100%;
  height: 40px;
    display: flex;
    justify-content: space-between;
    &--section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-right: 0.5px solid rgba(51, 54, 69, 0.1);
      border-bottom: 0.5px solid rgba(51, 54, 69, 0.1);
      width: 50%;
      height: 100%;
      padding: 0 25px;
      &--team {
        font-weight: bold;
        color: rgba(51, 54, 69, 1) !important;
      }
      &:first-child {
        border-left: 0.5px solid rgba(51, 54, 69, 0.1);
      }
      span {
        color: rgba(51, 54, 69, 0.5);
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}