.sportsBook-live {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 40px;
  .accordion-item {
    border: none !important
  }
  @media(max-width: 992px) {
    flex-direction: column;
  }
  &--left {
    width: 24%;
    position: relative;
    &--searchBox {
      width: 100%;
      input {
        background-image: linear-gradient(
          84deg,
          #1631ba 0%,
          #863cc4 100%
        ) !important;
        border-radius: 5px;
        border: none;
        background-color: #f8f8f8;
        width: 100%;
        height: 50px;
        font-size: 12px;
        color: #fff;
        padding: 20px;
        &::placeholder {
          color: #fff;
          opacity: 0.6;
        }
        &:focus {
          outline: none;
        }
      }
      img {
        position: absolute;
        right: 14px;
        top: 13px;
      }
    }

    &--live-bets {
      width: 100%;
      height: 737px;
      background-color: aqua;
      margin-top: 15px;
      border-radius: 10px;
    }
    @media(max-width: 992px) {
      margin-top: 10px;
      width: 100%;
    }
  }
  &--mid {
    width: 49%;
    &--topBtns {
      margin-bottom: 15px;
      .customBtn {
        &:first-child {
          margin-left: 0;
        }
      }
    }
    &--sortOptions {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .customBtn {
        &:first-child {
          margin-left: 0;
        }
      }
    }
    @media(max-width: 992px) {
      margin-top: 10px;
      width: 100%;
    }
  }
  &--right {
    width: 24%;
    &--topTabsBox {
      margin-bottom: 15px;
      border-radius: 5px;
      width: 100%;
      overflow: hidden;
      display: flex;
      &--tab {
        height: 50px;
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.25);
        background-color: #333645;
        cursor: pointer;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;
          border-radius: 2px;
          background-color: #787878;
          color: rgba(255, 255, 255, 0.25);
        }
      }
    }
    @media(max-width: 992px) {
      margin-top: 10px;
      width: 100%;
    }
  }
}

.sportsBookActiveTab {
  color: #fff;
  background-image: linear-gradient(84deg, #1631ba 0%, #863cc4 100%) !important;
  div {
    background-image: linear-gradient(
      84deg,
      #fe6740 0%,
      #f29626 100%
    ) !important;
  }
}

.sportsbook-live-favorites {
  padding: 15px;
  width: 100%;
  height: 377px;
  background-color: #333645;
  margin-top: 15px;
  border-radius: 10px;
  &--title {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    img {
      margin-right: 10px;
    }
    span {
      font-size: 18px;
      font-weight: 500;
      color: #fff;
    }
  }
  &--items {
    display: flex;
    flex-direction: column;
  }
}

.sportsbook-live-favoriteItem {
  width: 100%;
  height: 65px;
  display: flex;
  font-size: 13px;
  font-weight: normal;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.15);
  padding-bottom: 10px;
  margin-bottom: 10px;
  &:last-child {
    border-bottom: none;
  }
  &--iconBox {
    width: 24px;
    background-color: #1f2230;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
  &--matchInfo {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: auto;
    color: #fff;
    span {
      &:last-child {
        background: -webkit-linear-gradient(84deg, #fe6740 0%, #f29626 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  &--scores {
    margin-right: 10px;
    &--scoreBox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;
      background-color: #fff;
      color: #1f2230;
      border-radius: 5px;
      margin-top: 5px;
    }
  }
}
.sportsbook-live-liveBetsBox {
  padding: 15px;
  width: 100%;
  height: 737px;
  background-color: #333645;
  border-radius: 10px;
  margin-top: 15px;
  .accordion-button:not(.collapsed)::after {
    top: 10px;
    right: 15px;
    background-image: url(../../assets/sportsbook/chevronDownSB.svg);
    @media(min-width: 992px) and (max-width: 1200px) {
      right: 5px;
    }
  }
  .sportsbook-live-liveBetsBox--content--countries--item--header button::after {
    @media(min-width: 992px) and (max-width: 1200px) {
      right: 5px;
    }
  }
  .accordion-button:focus {
    box-shadow: none;
  }
  .accordion-button:not(.collapsed) {
    background-color: #3e414e;
    color: #fff;
  }
  .accordion-button {
    @media(min-width: 992px) and (max-width: 1200px) {
      padding: 10px;
    }
  }
  .accordion-item {
    border: none;
    background-color: transparent;
    // height: 40px;
  }
  .accordion-body {
    background-color: #1f2230;
    padding: 15px;
  }
  &--title {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    img {
      margin-right: 10px;
    }
    span {
      font-size: 18px;
      font-weight: 500;
      color: #fff;
    }
  }
  &--content {
    display: flex;
    justify-content: space-between;
    &--tabs {
      width: 25%;
      display: flex;
      flex-direction: column;
      &--tabItem {
        cursor: pointer;
        color: #fff;
        font-size: 14px;
        border-radius: 10px;
        background-color: #3e414e;
        width: 72px;
        height: 72px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        img {
          margin-bottom: 5px;
          @media(min-width: 992px) and (max-width: 1200px) {
            width: 18px;
          }
        }
        @media(min-width: 992px) and (max-width: 1200px) {
          width: 50px;
          height: 50px;
          font-size: 13px;
        }
        @media(min-width: 1200px) and (max-width: 1400px) {
          width: 60px;
          height: 60px;
          font-size: 13px;
        }
      }
    }
    &--countries {
      width: 70%;
      height: 650px;
      overflow-x: scroll;
      &--item {
        color: #fff;
        width: 100%;
        &--header {
          position: relative;
          span {
            &:last-child {
              color: #fff;
              opacity: 0.6;
              position: absolute;
              right: 45px;
              top: 14px;
              @media(min-width: 992px) and (max-width: 1200px) {
                right: 28px;
              }
            }
          }
          button {
            font-size: 12px;
            color: #fff;
            background-color: #3e414e;
            display: flex;
            outline: none !important;
            height: 40px;
            img {
              margin-right: 10px;
            }
            &::after {
              position: absolute;
              right: 10px;
              top: 14px;
              background-size: 15px 15px;
              background-image: url(../../assets/sportsbook/chevronDownSB.svg);
            }
          }
        }
        &--match {
          width: 100%;
          display: flex;
          font-size: 12px;
          padding-bottom: 10px;
          border-bottom: 0.5px solid rgba(255, 255, 255, 0.15);
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }
          &--left {
            display: flex;
            flex-direction: column;
          }
          &--right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: auto;
            span {
              width: 15px;
              height: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #fff;
              color: #1f2230;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}

.activeLiveBetsTab {
  background-color: #1f2230 !important;
}