.footer-column {
    color: #fff;
    &--title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 11px;
    }
    &--links {
        ul {
            list-style: none !important;
            padding-left: 0;
            margin-bottom: 0;
            li {
                a {
                    color: #fff;
                    text-decoration: none;
                }
                margin-top: 20px;
                cursor: pointer;
                &:first-child {
                    margin-top: 31px !important;
                }
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    @media(max-width: 1400px) {
        margin-top: 15px;
    }
    @media(max-width: 500px) {
        margin-top: 35px;
        padding-bottom: 20px;
        min-width: 180px !important;
        max-width: 180px !important;
        border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    }
}

.footer-mobile-links {
    color: #fff;
    &--title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 31px;
    }
    &--icons {
        display: flex;
        flex-direction: column;
        img {
            width: 125px;
            height: 42px;
            margin-bottom: 15px;
        }
    }
    @media(max-width: 1400px) {
        margin-top: 15px;
    }
    @media(max-width: 500px) {
        margin-top: 35px;
        padding-bottom: 20px;
        min-width: 180px;
        max-width: 180px;
        border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    }
}