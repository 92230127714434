.customBtn {
    width: 117.4px;
    height: 41px;
    border: none !important;
    border-radius: 5px !important;
    margin-left: 11.4px;
    font-size: 12px;
    img {
        margin-right: 10px;
    }
    &:hover {
        box-shadow: inset 0 0 0 2em var(--hover);
    }
    @media (max-width: 992px) {
        min-height: 52px;
    }
}

.loadingIndicatorBtn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    .spinner-border {
        margin-left: auto;
        --bs-spinner-width: 1.5rem !important;
        --bs-spinner-height: 1.5rem !important;
    }
}
