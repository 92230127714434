.register-popup {
    width: 100%;
    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
                font-size: 20px !important;
                margin-right: 10px;
                opacity: 0.5;
            }
        }
        span {
            &:first-child {
                font-size: 35px;
                font-weight: bold;
            }
        }
    }
    &__input-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        @media (min-width: 1024px) {
            .customInputWrapper {
                width: calc(100% / 2 - 15px);
            }
        }
        @media (max-width: 1024px) {
            flex-direction: column;
            .customInputWrapper {
                width: 100%;
            }
        }
    }
    &__textarea-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        .input-label {
            font-family: "Poppins";
            margin-bottom: 10px;
            text-align: start;
            font-size: 14.5px;
            margin-left: 5px;
        }
        textarea {
            margin-bottom: 30px;
            border: none;
            background-color: #f8f8f8;
            width: 100%;
            font-size: 12px;
            padding: 20px;
            border-radius: 5px;
            font-size: 14px;
            color: #171832;
            &:focus {
                outline: #7047ee;
                border: 1px solid #7047ee;
            }
        }
    }
    &__btn-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        @media (min-width: 1024px) {
            .buttonET {
                width: calc(100% / 2 - 15px);
            }
        }
        @media (max-width: 1024px) {
            flex-direction: column;
            .buttonET {
                width: 100%;
            }
        }
    }
    .selectETwrapper {
        width: 100%;
        @media (min-width: 1024px) {
            width: calc(100% / 2 - 15px);
        }
    }
}

.registerFWSelector {
    .selectETwrapper {
        width: 100% !important;
    }
}

.phoneInputWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media (min-width: 1024px) {
        // .customInputWrapper {
        width: calc(100% / 2 - 15px);
        // }
    }
    .selectETwrapper {
        max-width: 25%;
        margin-top: 10px;
    }
    .input-label {
        margin-left: -63px;
    }
    .customInputWrapper {
        width: 79%;
    }
}

.activeRegisterTab {
    font-size: 22px;
    font-weight: bold;
    opacity: 1 !important;
}

.register-calender {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media (min-width: 1024px) {
        width: calc(100% / 2 - 15px);
    }
    .selectETwrapper {
        width: 30%;
    }
}
