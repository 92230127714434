.profileSettings-wrapper {
    margin: 50px 0px;
    .btn-wrapper {
        display: flex;
        .loginModal--btn {
            width: unset;
            margin-right: 30px;
        }
        @media(max-width: 768px) {
            flex-direction: column !important;
            .loginModal--btn {
                width: 100% !important;
                button {
                    width: 100% !important;
                }
                margin-right: 0;
            }
        } 
    }
    &--header {
        color: #171832;
        font-size: 24px;
        font-weight: bold;
        display: block;
        margin-bottom: 25px;
    }
    &--inputs-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .inputBoxArea {
            input {
                width: 95% !important;
                max-width: 95% !important;
            }
        }
        @media (max-width: 768px) {
            flex-direction: column !important;
            .inputBoxArea {
                input {
                    width: 100% !important;
                    max-width: 100% !important;
                }
            }
        }
    }
    &--inputs-line-w-50 {
        width: 50%;
        display: flex;
        justify-content: space-between;
        .inputBoxArea {
            input {
                width: 95% !important;
                max-width: 95% !important;
            }
        }
        @media (max-width: 768px) {
            width: 100%;
            flex-direction: column !important;
            .inputBoxArea {
                input {
                    width: 100% !important;
                    max-width: 100% !important;
                }
            }
        }
    }
}

.Mfa-Modal {
    .modal-header {
        position: relative !important;
        border-bottom: 0 !important;
        .modal-title {
            margin: auto;
        }
        button {
            margin: 0;
            padding: 0;
            position: absolute !important;
            right: 20px;
        }
    }
    .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            text-align: center;
            font-size: 14px;
        }
        img {
            max-width: 200px;
            max-height: 200px;
        }
        span {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        input {
            margin-bottom: 20px;
            border: none;
            background-color: #f8f8f8;
            width: 200px;
            height: 50px;
            font-size: 12px;
            padding: 20px;
            &:focus {
                outline: none;
            }
            &::placeholder {
                text-align: center;
            }
        }
        button {
            margin-bottom: 20px;
        }
    }
}

.MFA-info-box {
    width: 100%;
    background-color: #e9ecef;
    margin-bottom: 15px;
    &__title {
        padding: 15px;
        font-size: 16px;
        font-weight: bold;
        background-color: #d9dcde;
        margin-bottom: 10px;
    }
    &__p1 {
        padding: 0 15px;
        font-size: 15px;
        margin-bottom: 10px;
        color: #1631ba;
    }
    &__p2 {
        padding: 0 15px;
        font-size: 14px;
        margin-bottom: 10px;
    }
}
