.modalEt {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9998;
    &_popup {
        max-height: 90vh;
        overflow: scroll;
        padding: 30px;
        min-width: 400px;
        // min-height: 400px;
        height: max-content;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        z-index: 9999;
        @media (max-width: 768px) {
            min-width: 90% !important;
            max-width: 90%;
        }
    }
    &__closeIcon {
    }
}

.modalEt-closeIcon {
    cursor: pointer;
    position: absolute;
    top: -12px;
    right: -8px;
    max-height: 20px;
    max-width: 20px;
}
