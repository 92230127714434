@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap");
.customInputWrapper {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    font-family: "Poppins";
    .input-label {
        margin-bottom: 10px;
        text-align: start;
        font-size: 14px;
        margin-left: 5px;
    }
    .input {
        position: relative;
        img {
            height: 20px;
        }
        input {
            border: none;
            background-color: #f8f8f8;
            width: 100%;
            height: 50px;
            font-size: 12px;
            padding: 20px;
            border-radius: 5px;
            font-size: 14px;
            color: #171832;
            &:focus {
                outline: #7047ee;
                border: 1px solid #7047ee;
            }
        }
        .inputErrorText {
            margin-top: 5px;
            font-size: 13px;
            text-align: left;
            margin-left: 20px;
            color: red;
        }
    }
    .password-icon {
        position: absolute;
        right: 20px;
        top: 15px;
    }
    .input-disabled {
        opacity: 0.7;
    }
}

.input-error {
    color: red !important;
    input {
        border: 1px solid red !important;
        color: red !important;
    }
}
