.buttonET {
    height: 45px;
    border: none;
    font-size: 14px;
    font-weight: normal;
    border-radius: 5px;
    margin-top: 20px;
    width: 100%;
}
.buttonET-disabled {
    opacity: 0.5;
    pointer-events: none;
}

.loadingIndicatorETBtn {
    opacity: 0.7;
    pointer-events: none;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    .spinner-border {
        margin-left: auto;
        --bs-spinner-width: 1.5rem !important;
        --bs-spinner-height: 1.5rem !important;
    }
}

.primaryBtn {
    color: rgb(255, 255, 255);
    background-image: linear-gradient(84deg, rgb(134, 60, 196) 0%, rgb(22, 49, 186) 100%);
}

.secondaryBtn {
    color: rgb(255, 255, 255);
    background-image: linear-gradient(248deg, rgb(254, 102, 65) 100%, rgb(255, 161, 27) -2%);
}

.tertiaryBtn {
    color: #5036bf;
    border: 1px solid #5036bf;
    background-color: #fff;
    font-size: 15px;
}

.quaternaryBtn {
    color: rgb(254, 102, 65);
    border: 1px solid rgb(254, 102, 65);
    background-color: #fff;
    font-size: 15px;
}
