.betAndLiveCasSection {
    margin-top: 50px;
    margin-bottom: 37px;
    display: flex;
    justify-content: space-between;
    &--leftSide {
        width: 49%;
        &--titles {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    &--rightSide {
        width: 49%;
        &--titles {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    @media(max-width: 1199px) {
        flex-direction: column;
        &--leftSide {
            width: 100%;
            margin-bottom: 40px;
        }
        &--rightSide {
            width: 100%;
        }
    }
}

.betCards {
    display: flex;
    justify-content: space-between;
    overflow-x: scroll;
    overflow-y: hidden;
}

.betCard {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    min-width: 310px;
    min-height: 251px;
    padding: 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 17px;
    margin-bottom: 21px;
    background-color: #fff;
    &:first-child {
        margin-left: 5px;
    }
    &--top {
        color: #333645;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &--left {
            display: flex;
            flex-direction: column;
            span {
                &:first-child {
                    font-size: 10px;
                }
                &:last-child {
                    font-size: 11px;
                    font-weight: bold;
                }
            }
        }
    }
    &--mid {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &--teams-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 14px;
            font-weight: normal;
            span {
                &:nth-child(2) {
                    width: 34px;
                    height: 0.5px;
                    background-color: #333645;
                    margin: 12.5px 0 11px 0;
                }
            }
        }
    }
    &--bot {
        display: flex;
        justify-content: space-between;
        &--bet-ratio-box {
            width: 87px;
            height: 32px;
            border-radius: 5px;
            background-color: #f2f2f2;
            padding: 6px 12px 7px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
                color: #333645;
                font-size: 14px;
                font-weight: normal;
                &:nth-child(2) {
                    width: 0.5px;
                    height: 14px;
                    background-color: #333645;
                }
            }
        }
    }
}