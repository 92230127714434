.payment-btn-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    &__btn {
        width: 200px;
        height: 350px;
        background: red;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        font-weight: 550;
        color: #ffffff;
        border-radius: 5px;
        margin-right: 50px;
        @media (max-width: 768px) {
            margin-right: 0px;
            margin-bottom: 20px;
            width: 50%;
        }
        @media (max-width: 470px) {
            margin-right: 0px;
            margin-bottom: 20px;
            width: 100%;
        }
    }
}

.primary-payment-bg {
    color: rgb(255, 255, 255);
    background-image: linear-gradient(84deg, rgb(134, 60, 196) 0%, rgb(22, 49, 186) 100%);
}

.secondary-payment-bg {
    color: rgb(255, 255, 255);
    background-image: linear-gradient(248deg, rgb(254, 102, 65) 100%, rgb(255, 161, 27) -2%);
}