.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.2);
}

.header {
  z-index: 1;
  width: 100%;
}
.headerTop {
  display: flex;
  align-items: center;
  height: 57px;
  max-height: 57px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important;
  background-image: linear-gradient(84deg, #863cc4 0%, #1631ba 100%) !important;
  &__wrapper {
    max-height: 57px !important;
    display: flex;
    justify-content: space-between;
    .headerBtn {
      height: 57px;
      font-size: 14px;
      cursor: pointer;
      border-left: 1px solid rgba(255, 255, 255, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px 0 17px;
      color: #fff;
      a {
        color: #fff;
        text-decoration: none;
      }
      &:last-child {
        border-right: 1px solid rgba(255, 255, 255, 0.15);
      }
      &:hover {
        backdrop-filter: blur(20px);
      }
    }
    &__left {
      display: flex;
    }
    &__right {
      display: flex;
      .headerBtn {
        &:first-child {
          border-left: none !important;
          img {
            margin-right: 5.7px;
            width: 29px;
            height: 33px;
          }
          font-weight: bold;
        }
        &:nth-child(2) {
          img {
            margin-right: 6px;
            width: 32px;
            height: 24px;
          }
          font-weight: bold;
        }
        &:nth-child(3) {
          img {
            width: 37px;
            height: 37px;
            border-radius: 50%;
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    display: none;
  }
}
.headerMid {
  height: 92px !important;
  background-image: linear-gradient(84deg, #863cc4 0%, #1631ba 100%) !important;
  &__wrapper {
    height: 92px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--right {
      @media (max-width: 991px) {
        display: none;
      }
    }
    @media (min-width: 991px) {
      &--mobileIcons {
        display: none;
      }
    }
  }
  @media (max-width: 991px) {
  }
}
.headerBot {
  height: 91px !important;
  background-color: #4d506c !important;
  position: relative;
  &__wrapper {
    height: 91px;
    &--left {
      height: 100%;
      display: flex;
      a {
        text-decoration: none;
      }
      .navItemWrapper {
        &:nth-child(7) {
          .navItem-line {
            border-right: none !important;
          }
        }
      }
    }
    &--promotion {
      width: 538px;
      height: 100%;
      // background-image: linear-gradient(260deg, #fe6740 100%, #f29726 4%);
      background-image: linear-gradient(90deg, #f29726 20%, #fe6740 100%);
      position: absolute;
      right: 0;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      img {
        position: absolute;
        left: -20px;
        top: 15px;
      }
      span {
        display: block;
        font-weight: 800;
        font-size: 18px;
        color: #fff;
        margin-left: 50px;
        margin-top: 30px;
      }
    }
  }
  @media (max-width: 1487px) {
    display: none;
  }
}

.mobile-menu-modal {
  background-image: linear-gradient(84deg, #1631ba 0%, #1631ba 100%);
  position: relative;
  .offcanvas-header {
    justify-content: flex-start !important;
    margin-left: 10px;
    margin-top: 30px;
    button {
      position: absolute;
      right: 20px;
    }
  }
  .offcanvas-body {
    overflow: hidden;
  }
  .btn-close {
    opacity: 1;
    background: url(../../../assets/icons/close.svg) center/1em auto no-repeat;
  }
}

.mobile-menu {
  width: 100%;
  height: 100%;
  position: relative;
}

.mb-active {
  display: flex !important;
  flex-direction: column;
  margin-top: 10px;
  @media (min-width: 992px) {
    display: none !important;
  }
  .headerTop {
    height: auto !important;
    display: flex;
    border-bottom: none !important;
    background-image: none !important;
    &__wrapper {
      .headerBtn {
        border: none !important;
      }
      flex-direction: column;
      &__left {
        display: none;
      }
      &__right {
        flex-direction: column;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        flex-wrap: nowrap;
        justify-content: space-around;
        @media (max-width: 549px) {
          flex-direction: column;
          margin-top: calc(100vh - 265px);
          .headerBtn {
            border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important;
          }
        }
      }
    }
  }
  .navItemWrapper {
    height: 40px;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }
  .navItemWrapper .navItem {
    flex-direction: row;
    font-size: 14px;
    span {
      &:first-child {
        width: 50px !important;
        height: 50px !important;
      }
      &:last-child {
        margin-left: 15px;
      }
    }
  }
  .headerBot {
    display: flex;
    background-color: transparent !important;
    .navItem-line {
      display: none !important;
    }
    &__wrapper {
      &--left {
        width: 100%;
        position: absolute;
        top: 0px;
        flex-direction: column;
      }
      &--promotion {
        display: none;
      }
    }
  }
  .mobile-menu--bottom {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    .customBtn {
      margin-bottom: 10px;
      &:last-child {
      }
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
  .mobile-menu--lang {
    position: absolute;
    bottom: 10px;
    right: 0;
  }
}

@keyframes myAnim {
  0% {
    opacity: 0;
    transform: translateX(-250px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.custom-modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.78);
  z-index: 100;
  width: 100%;
  height: 100%;
}

.mobile-user-modal {
  width: 100% !important;
  height: calc(100% - 92px);
  margin-top: auto;
  background-color: red;
  background-image: url(../../../assets/png/footerbg.svg);
  .offcanvas-header {
    display: none;
  }
  &--body {
    overflow: hidden;
    width: 100%;
    height: 100%;

    &--welcome {
      font-size: 18px;
      font-weight: bold;
      color: #171832;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
    &--walletCards {
      display: flex;
      justify-content: space-between;
      margin-bottom: 31px;
      &--walletCard {
        width: 130px;
        height: 120px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:first-child {
          background-image: linear-gradient(to right, #de8d2e 0%, #fa713a 100%);
        }
        &:nth-child(2) {
          background-image: linear-gradient(to right, #863cc4 0%, #1631ba 100%);
        }
        &:last-child {
          background-image: linear-gradient(to right, #860000 0%, #f00 100%);
        }
        &--title {
          font-size: 14px;
        }
        img {
          width: 36px;
          height: 36px;
          margin-bottom: 5px;
        }
        span {
          color: #fff;
          font-weight: bold;
          &:first-child {
            font-size: 14px;
          }
          &:last-child {
            font-size: 18px;
          }
        }
      }
    }
    &--settingsBox {
      display: flex;
      justify-content: space-between;
      &--settingBox {
        width: 49%;
        height: 40px;
        background-color: rgba(23, 24, 50, 0.3);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        opacity: 0.3;
      }
    }
    &--accountSettings {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 31px;
    }
    &--walletSettings {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 31px;
    }
    &--optionsBox {
      width: 49%;
      height: 40px;
      background-color: transparent;
      border-radius: 5px;
      border: solid 1px #171832;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      img {
        margin-right: 5px;
      }
    }
    &--btns {
      .customBtn {
        margin-left: 0;
      }
      display: flex;
      justify-content: space-between;
    }
  }
}

.offcanvas-backdrop.show {
  opacity: 0 !important;
}

.activeSettingsBox {
  background-color: #171832 !important;
  color: #fff;
  opacity: 1;
}
