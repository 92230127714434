@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap");
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
/* * {
  height: 100%;
  width: 100%; 
} */
* {
  font-family: "Poppins";
}

.slick-slider {
  overflow: hidden;
}

/* .slick-slider .slick-slide {
  padding: 0 17.1px;
} */

.slick-list {
  margin: 0 -40px !important;
}

.slick-slide > div {
  padding: 0 40px !important;
}

.tab-title {
  color: #5036bf;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -1.2px;
  margin: 0;
}

.showAll {
  display: flex;
  align-items: center;
}

.showAllBtn {
  color: #5036bf;
  font-size: 14px;
  margin: 0 22px !important;
}

.showAllBtn a {
  color: #5036bf;
  text-decoration: none;
}
.showAllBtnIcons .fa-chevron-left {
  color: #5036bf !important;
}

.showAllBtnIcons .fa-chevron-right {
  color: #5036bf !important;
}

.tab-title-underLine {
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background-image: linear-gradient(87deg, #f29626 0%, #fe6740 100%);
  margin: 10px 0 19px 0;
}

.gameCardsCont {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
}

.gameCardsCont ::-webkit-scrollbar {
  height: 4px !important;
  width: 4px !important;
}

.gameCardsCont::-webkit-scrollbar {
  height: 4px;
}
.gameCardsCont::-webkit-scrollbar-track {
  border-radius: 5px;
}
.gameCardsCont::-webkit-scrollbar-thumb {
  background-image: linear-gradient(84deg, #1631ba 0%, #863cc4 100%) !important;
  border-radius: 5px;
}

.betCardsCont ::-webkit-scrollbar {
  height: 4px !important;
  width: 4px !important;
}

.betCardsCont::-webkit-scrollbar {
  height: 4px;
}
.betCardsCont::-webkit-scrollbar-track {
  border-radius: 5px;
}
.betCardsCont::-webkit-scrollbar-thumb {
  background-image: linear-gradient(84deg, #1631ba 0%, #863cc4 100%) !important;
  border-radius: 5px;
}

*::-webkit-scrollbar {
  height: 4px !important;
  width: 4px !important;
}

*::-webkit-scrollbar {
  height: 4px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
}
*::-webkit-scrollbar-thumb {
  background-image: linear-gradient(84deg, #1631ba 0%, #863cc4 100%) !important;
  border-radius: 5px;
}

.sportsBook-accordions .accordion {
  margin-bottom: 15px;
}

.sportsBook-accordions .accordion-item {
  background-color: transparent !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.sportsBook-accordions .accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  background-image: linear-gradient(84deg, #863cc4 0%, #1631ba 100%) !important;
  color: #fff;
  font-size: 14px;
  max-height: 50px;
}

.sportsBook-accordions .accordion-button::after {
  /* margin-top: 9px; */
  background-image: url(./assets/sportsbook/chevronDownSB.svg) !important;
}

.sportsBook-accordions .accordion-header {
  display: flex;
  align-items: center;
}

.sportsBook-accordions .accordion-button img {
  max-width: 15px;
  max-height: 15px;
  margin: 0;
  margin-right: 10px;
}

.sportsBook-accordions .accordion-body {
  padding: 0;
  background-color: #fff;
}

.sportsBook-accordions .accordion-button:not(.collapsed) {
  color: #fff !important;
}
.sportsBook-accordions .accordion-button:focus {
  border: none !important;
  box-shadow: none !important;
}

.sportsBook-accordions-black .accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  background-image: none !important;
  background-color: #333645 !important;
  color: #fff;
  font-size: 14px;
  max-height: 50px;
}