.customModal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.78);
  z-index: 2;
  display: flex;
  justify-content: center;
}
// .loginErrorModal {
//   height: max-content !important;
//   padding: 50px 40px 30px 40px !important;
// }

.loginModal1 {
  margin-top: 10%;
  // width: 448px;
  // height: 577px;
  background-color: #fff;
  border-radius: 10px;
  // padding: 90px 50px 100px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #171832;
  form {
    width: 100%;
  }
  .inputBoxArea {
    position: relative;
    width: 100%;
  }
  input {
    border: none;
    background-color: #f8f8f8;
    width: 100%;
    height: 50px;
    font-size: 12px;
    padding: 20px;
    &:focus {
      outline: none;
    }
  }
  &--title {
    font-size: 35px;
    font-weight: bold;
    color: #171832;
    margin-bottom: 10px;
  }
  &--userName {
    font-size: 16px;
    margin-bottom: 20px;
    width: 100%;
    span {
      display: flex;
      margin-bottom: 10px;
    }
  }
  &--password {
    margin-bottom: 20px;
    width: 100%;
    cursor: pointer;
    span {
      display: flex;
      margin-bottom: 10px;
    }
  }
  &--btn {
    margin-bottom: 30px;
    width: 100%;
    .customBtn {
      margin-left: 0 !important;
    }
  }
  &--forgetpassword {
    font-size: 14px;
    color: #7047ee;
    margin-bottom: 24px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &--signin {
    width: 100%;
    display: flex;
    justify-content: center;
    span {
      &:first-child {
        font-size: 14px;
        color: #171832;
        margin-right: 7px;
      }
      &:last-child {
        font-size: 14px;
        font-weight: bold;
        color: #7047ee;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    @media (max-width: 425px) {
      flex-direction: column;
      align-items: center;
    }
  }
}

.input-area {
  position: relative;
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
}

.registerModal {
  width: 816px;
  height: 887px;
  margin-top: 1.5%;
  background-color: #fff;
  border-radius: 10px;
  padding: 90px 50px 100px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #171832;
  padding-top: 67px;
  form {
    width: 100%;
  }
  &--header {
    width: 100%;
    font-size: 35px;
    font-weight: bold;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    &--tabs {
      display: flex;
      &--tab {
        cursor: pointer;
        font-size: 24px !important;
        font-weight: normal !important;
        color: rgba(23, 24, 50, 0.3);
        margin-left: 30px;
        @media (max-width: 850px) {
          font-size: 15px !important;
          margin-left: 0px !important;
          margin-right: 15px;
          white-space: nowrap;
        }
      }
      @media (max-width: 850px) {
        margin-top: 15px;
      }
    }
    @media (max-width: 850px) {
      margin-top: 20px;
      font-size: 25px;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &--personalInfo {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
    &--bottomBtns {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .customBtn {
        margin-left: 0;
      }
    }
    @media (max-width: 850px) {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
  &--otherInfo {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
    &-bottom {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .inputBoxArea {
        width: 100% !important;
      }
      @media (max-width: 850px) {
        height: auto;
      }
    }
    @media (max-width: 850px) {
      justify-content: none;
    }
  }
  @media (max-width: 850px) {
    width: 95%;
    height: 1250px !important;
    padding: 0px 35px 35px 35px;
    max-height: 1300px;
  }
  .confirmationBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    .inputBoxArea {
      width: 100%;
    }
  }
}

.activeTab {
  color: #171832 !important;
}

.inputBoxArea {
  display: flex;
  flex-direction: column;
  width: 49%;
  margin-bottom: 20px;
  input {
    padding: 20px;
    width: 100%;
    background-color: #f8f8f8;
    border: none !important;
    height: 50px;
    font-size: 14px;
    &:focus {
      outline: none !important    ;
    }
  }
  textarea {
    padding: 20px;
    min-width: 100%;
    background-color: #f8f8f8;
    border: none !important;
    font-size: 14px;
    &:focus {
      outline: none !important    ;
    }
  }
  @media (max-width: 850px) {
    width: 100%;
  }
  select {
    height: 50px;
    border: none;
    background-color: #f8f8f8;
    font-size: 14px;
  }
}

.inputBoxArea-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 20px;
  label {
    display: flex;
    span {
      margin-left: 5px;
    }
  }
  &--text-link {
    color: #7047ee;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.depositModal {
  background-color: #fff;
  border-radius: 15px;
  width: 550px;
  // height: 816px;
  height: max-content;
  z-index: 5;
  @media (max-width: 750px) {
    // padding: 35px;
    // height: 1145px;
    width: auto;
  }
  &--top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    &--title {
      font-size: 35px;
      font-weight: bold;
      color: #171832;
      @media (max-width: 750px) {
        font-size: 22px;
      }
    }
  }
  &--mid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .inputBoxWithoutForm {
      width: 100%;
    }
    @media (max-width: 750px) {
      .inputBoxWithoutForm {
        width: 100%;
      }
    }
  }
  &--bot {
    .inputBoxWithoutForm {
      width: 100%;
    }
    &--infoSec {
      font-size: 14px;
      color: #717181;
      margin-top: 20px;
      &--topSpan {
        margin-bottom: 25px;
      }
      span {
        display: block;
      }
      &--rulesTitle {
      }
      &--rule {
      }
    }
  }
  &--wantBonus {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    width: 100%;
    background-color: #f8f8f8;
    font-size: 14px;
    color: rgba(23, 24, 50, 0.6);
    padding: 0 20px;
    border-radius: 5px;
    margin-top: 10px;
  }
  &--btns {
    // margin-top: 21px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .customBtn {
      margin: 0;
    }
  }
  &--promotionCodeResult {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    img {
      width: 100px;
    }
    span {
      margin-top: 20px;
      font-family: Poppins;
      font-size: 15px;
    }
  }
}

.datepicker {
  .inputBoxArea {
    input {
      height: 10px !important;
      padding: 0 !important;
    }
  }
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  height: 50px !important;
  input {
    background-color: transparent;
  }
}

.wantBonusActive {
  background-image: linear-gradient(87deg, #f29626 0%, #fe6740 100%);
  color: #fff;
}

.inputBoxWithoutForm {
  text-align: left;
  margin-bottom: 10px;
  span {
    display: block;
    color: #171832;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 10px;
  }
  .form-select {
    height: 50px;
    color: rgba(23, 24, 50, 0.6);
    font-size: 14px;
  }
  input {
    width: 100%;
    background-color: #f8f8f8;
    color: rgba(23, 24, 50, 0.6);
    border: none;
    height: 50px;
    border-radius: 5px;
    font-size: 14px;
    padding-left: 10px;
  }
}

.phoneInput {
  display: flex;
  width: 350px;
  height: 74px;
  @media (max-width: 992px) {
    width: 100%;
  }
  .inputBoxArea {
    width: 250px;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
  &--select {
    margin-top: auto;
    margin-right: 10px;
    height: 50px;
    max-width: 80px;
  }
}

.inputBoxArea {
  position: relative !important;
}
.inputBox-password-show-icon {
  position: absolute;
  right: 10px;
  top: 35px;
  cursor: pointer;
}

.error-show-icon {
  right: 30px !important;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap");

.loginModal {
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #171832;
  &--forgetpassword {
    font-size: 14px;
    color: #7047ee;
    margin-bottom: 24px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &--btn {
    margin-bottom: 30px;
    width: 100%;
    .customBtn {
      margin-left: 0 !important;
    }
  }
  &--signin {
    width: 100%;
    display: flex;
    justify-content: center;
    span {
      &:first-child {
        font-size: 14px;
        color: #171832;
        margin-right: 7px;
      }
      &:last-child {
        font-size: 14px;
        font-weight: bold;
        color: #7047ee;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    @media (max-width: 425px) {
      flex-direction: column;
      align-items: center;
    }
  }
}

.creditCard-wrapper {
  background-color: #f8f8f8;
  margin-bottom: 20px;
  #field-wrapper {
    background-color: #f8f8f8;
  }
  input {
    background-color: #f8f8f8;
    height: 50px;
  }
}

.prefix-input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  input {
    padding-left: 40px !important;
  }
  span {
    font-size: 15.5px;
    position: absolute;
    left: 18px;
    z-index: 10;
    top: 44.3px;
  }
}

.cepbank-prefix {
  input {
    padding-left: 50px !important;
  }
  span {
    top: 46px;
  }
}