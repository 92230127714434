.allMatches {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  @media(max-width: 1200px) {
    flex-direction: column;
  }
  .customBtn {
    display: flex;
    align-items: center;
    &:first-child {
      margin-left: 0;
    }
  }
  &--left {
    width: 75%;
    &--topBtns {
      width: 100%;
      display: flex;
      justify-content: space-between;
      div {
        display: flex;
        @media(max-width: 992px) {
          margin-bottom: 10px;
        }
      }
      @media(max-width: 992px) {
        flex-direction: column;
      }
    }
    &--topOptionsBar {
      margin-top: 15px;
      width: 100%;
      height: 50px;
      border-radius: 10px;
      background-color: #333645;
      display: flex;
      font-size: 14px;
      overflow: hidden;
      &--item {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 130px;
        min-width: 130px;
        color: #fff;
        position: relative;
        &:last-child {
          .optionBorder {
            display: none;
          }
        }
        img {
          margin-right: 10px;
          max-width: 18px;
        }
      }
      @media(max-width: 992px) {
        margin-top: 5px;
        overflow: scroll;
      }
    }
    &--sportsAndMatches {
      display: flex;
      flex-direction: column;
    }
    @media(max-width: 1200px) {
      width: 100%;
    }
  }
  &--right {
    width: 24%;
    &--topTabsBox {
      margin-bottom: 15px;
      border-radius: 5px;
      width: 100%;
      overflow: hidden;
      display: flex;
      &--tab {
        height: 50px;
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.25);
        background-color: #333645;
        cursor: pointer;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;
          border-radius: 2px;
          background-color: #787878;
          color: rgba(255, 255, 255, 0.25);
        }
      }
    }
    @media(max-width: 1200px) {
      margin-top: 20px;
      width: 100%;
    }
  }
}

.optionBorder {
  width: 1px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.15);
  position: absolute;
  right: 0;
}
.activeOption {
  background-image: linear-gradient(84deg, #863cc4 0%, #1631ba 100%);
}
.sportHeader {
  width: 100%;
  height: 50px;
  background-image: linear-gradient(84deg, #863cc4 0%, #1631ba 100%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  color: #fff;
  font-size: 14px;
  margin-top: 15px;
  &--left {
  }
  &--right {
    display: flex;
    width: 614px;
    &--column {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 140.5px;
      height: 50px;
      &:last-child {
      }
    }
  }
  img {
    max-width: 18px;
    margin-right: 10px;
  }
  @media(max-width: 992px ) {
    display: none;
  }
}
.matchBetRadioHeader {
  height: 40px;
  width: 100%;
  background-color: #333645;
  margin-top: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  overflow: hidden;
  font-size: 14px;
  color: #fff;
  @media (max-width: 1400px) {
    font-size: 12px;
  }
  &--teamAndMatchInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: auto;
    width: 360px;
    padding: 0 20px;
  }
  &--betRatios {
    display: flex;
    &--ratioColumn {
      border-left: 0.5px solid rgba(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 140.5px;
      height: 100%;
      padding: 0 20px;
      &:first-child {
        @media(max-width: 992px) {
          display: flex !important;
        }
      }
      &:last-child {
        width: 52px;
        justify-content: center;
        @media(max-width: 992px) {
          display: flex !important;
        }
      }
      @media(max-width: 992px) {
        display: none;
      }
    }
  }
}
.matchBetRadio {
  height: 52px;
  width: 100%;
  display: flex;
  overflow: hidden;
  font-size: 14px;
  @media (max-width: 1400px) {
    height: 45px;
  }
  &--teamAndMatchInfo {
    display: flex;
    align-items: center;
    margin-right: auto;
    width: 360px;
    color: rgba(51, 54, 69, 0.5);
    &--matchTime {
      &--green {
        background-color: #29b50e;
        width: 54px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #fff;
        margin-right: 10px;
        @media (max-width: 1400px) {
          width: 45px;
          height: 45px;
        }
      }
      &--blue {
        background-color: #0e5bb5;
        width: 54px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #fff;
        margin-right: 10px;
        @media (max-width: 1400px) {
          width: 45px;
          height: 45px;
        }
      }
    }
    &--teamNames {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      justify-content: flex-start;
      @media(max-width: 992px) {
        font-size: 12px;
      }
    }
    &--score {
      margin-left: auto;
      margin-right: 25px;
    }
  }
  &--betRatios {
    display: flex;
    &--ratioColumn {
      border-left: 0.5px solid rgba(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 140.5px;
      height: 100%;
      padding: 0 5px;
      &:first-child {
        @media(max-width: 992px) {
          display: flex !important;
        }
      }
      &:last-child {
        width: 52px;
        justify-content: center;
        @media(max-width: 992px) {
          display: flex !important;
        }
      }
      @media(max-width: 992px) {
        display: none;
      }
    }
  }
}

.betRatioBox {
  width: 37px;
  height: 26px;
  border: 1px solid #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.betRatioBox-disabled {
  background-color: #c4c4c4;
  color: #9c9c9c;
}
.betRatioBox-red {
  color: #fff;
  background-color: #be0404;
}

.ratioHeaderAndRatiosWrapper {
  .matchBetRadio {
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}
.activeTab {
  color: #fff;
  .allMatches--right--topTabsBox--tab--count {
    color: #fff;
  }
}
