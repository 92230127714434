.tabs {
    display: flex;
    flex-direction: column;
}

.tabItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #73778b;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    cursor: pointer;
    &--dot {
        border-radius: 50%;
        width: 4px;
        height: 4px;
        background-color: #73778b;
        margin-right: 20px;
        margin-left: 23px;
    }
    &--icon {
        margin: 0px 10px;
    }
    &--title {
        font-size: 14px;
        font-weight: 600;
    }
    @media(max-width: 992px) {
        color: #fff;
    }
}

.active {
    background-color: rgba(36, 50, 187, 0.1);
    background-image: url(../../../assets/png/tabsbg.svg);
    color: #062046 !important;
    .tabItem--dot {
        background-color: #2432bb !important;
    }
    @media(max-width: 992px) {
        color: #fff !important;
        background-image: none;
        background-color: rgba(255,255,255,0.2);
        .tabItem--dot {
            background-color: #1631ba !important;
        }
    }
}
.tabItemAccordionWrapper {
    .accordion:focus {
        outline: none !important;
        box-shadow: none !important;
    }
    .accordion-item {
        border: none !important;
        padding: 0 !important;
        &:focus {
            box-shadow: none !important;
        }
    }
    .accordion-header {
        position: relative;
        button {
            padding: 0 !important;
            &:after {
                position: absolute;
                right: 20px;
            }
        }
        @media(max-width: 992px) {
            button {
                background-color: transparent;
            }
        }
    }
    .accordion-button:focus {
        border-color: transparent !important;
        box-shadow: none !important;
    }
    .accordion-button:not(.collapsed) {
        background-color: transparent !important;
        box-shadow: none !important;
    }
    @media(max-width: 992px) {
        .accordion-item {
            background-color: transparent;
        }
    }
}