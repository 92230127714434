.sportsbook {
  width: 100%;
  display: flex;
  flex-direction: column;
  &--top {
    width: 100%;
    background-color: #f2f2f2;
    background-image: url(../../assets/png/tabsbg.svg);
    &--eventsCards {
      width: 100%;
      height: 331px;
      position: relative;
      display: flex;
      align-items: center;
      overflow-x: scroll;
      .betCard {
        margin-bottom: 0;
      }
    }
    &--eventsLine {
      width: 100%;
      height: 60px;
      background-color: #333645;
      display: flex;
      align-items: center;
      .slick-slide > div {
        padding: 0 80px !important;
      }
      /* the parent */
      .slick-list {
        margin: 0 -60px !important;
      }
    }
  }
  &--content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;
    margin-top: 40px;
    &--left {
      width: 24%;
      display: flex;
      flex-direction: column;
      position: relative;
      &--searchBox {
        width: 100%;
        input {
          background-image: linear-gradient(
            84deg,
            #1631ba 0%,
            #863cc4 100%
          ) !important;
          border-radius: 5px;
          border: none;
          background-color: #f8f8f8;
          width: 100%;
          height: 50px;
          font-size: 12px;
          color: #fff;
          padding: 20px;
          &::placeholder {
            color: #fff;
            opacity: 0.6;
          }
          &:focus {
            outline: none;
          }
        }
        img {
          position: absolute;
          right: 14px;
          top: 13px;
        }
      }
      &--matchesBox {
        width: 100%;
        max-height: 624px;
        overflow-y: hidden;
        margin-top: 10px;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
        &--matchesBoxItem {
          width: 50%;
          height: 155px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 0.5px solid rgba(0, 0, 0, 0.1);
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
        }
      }
      &--sportsOptions {
        height: 737px;
        width: 100%;
        background-color: #333645;
        border-radius: 10px;
        padding: 20px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        .tab-title-underLine {
          margin: 15px 0;
        }
        &--title {
          font-size: 18px;
          font-weight: bold;
          color: #fff;
        }
        &--option {
          cursor: pointer;
          font-size: 14px;
          font-weight: normal;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 11px 5px;
          border-bottom: 0.5px solid rgba(255,255,255, 0.25);
          img {
            margin-right: 10px;
          }
          &:last-child {
            border-bottom: none!important;
            margin-bottom: 0px;
          }
        }
      }
      @media(max-width: 993px) {
      width: 100%;
    }
    }
    &--mid {
      width: 49%;
      img {
        max-width: 100%;
        border-radius: 10px;
        margin-top: 10px;
        margin-bottom: 15px;
      }
      &--topBtns {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .customBtn {
          &:first-child {
            margin-left: 0;
          }
          img {
            width: 18px;
            height: 18px;
            border-radius: 0 !important;
          }
        }
      }
      @media(max-width: 993px) {
      width: 100%;
      margin-top: 20px;
    }
    }
    &--right {
      width: 24%;
      &--topTabsBox {
        margin-bottom: 10px;
        border-radius: 5px;
        width: 100%;
        overflow: hidden;
        display: flex;
        &--tab {
          height: 50px;
          display: flex;
          width: 50%;
          align-items: center;
          justify-content: space-between;
          padding: 15px;
          font-size: 14px;
          font-weight: bold;
          color: rgba(255,255,255, 0.25);
          background-color:#333645;
          cursor: pointer;
          div{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            border-radius: 2px;
            background-color:#787878;
            color: rgba(255,255,255, 0.25);
          }
        }
      }
      &--betsBox {
        height: 605px;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        background-color: #333645;
        margin-bottom: 15px;
        &--tabs {
          display: flex;
          background-image: linear-gradient(84deg, #fe6740 0%, #f29626 100%) !important;  
          &--tab {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% / 3);
            height: 40px;
            font-size: 14px;
            font-weight: normal;
            color: #fff;
          }
        }
        &--content {
          height: 100%;
          padding: 10px;
          &--cod {
            font-size: 14px;
            color: #fff;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
          }
          &--event {
            height: 132px;
            width: 100%;
            border-radius: 10px;
            background-color: #464a5c;
            margin-bottom: 10px;
            padding: 10px;
            &--top {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 14px;
              color: #fff;
              border-bottom: 1px solid rgba(255,255,255, 0.25);
              padding-bottom: 10px;
              div {
                display: flex;
                align-items: center;
                justify-content: center;
              }
              input {
                margin-right: 10px;
              }
              img {
                margin-left: 10px;
                cursor: pointer;
              }
              div{
                &:last-child {
                  span {
                    font-weight: bold;
                    background: -webkit-linear-gradient(84deg, #fe6740 0%, #f29626 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
              }
            }
            &--bot {
              display: flex;
              flex-direction: column;
              margin-top: 10px;
              font-weight: 600;
              font-size: 14px;
              color: #fff;
              span {
                &:last-child {
                  font-size: 12px !important;
                  font-weight: normal;
                }
              }
            }
          }
          &--selectedBetsInfo {
            font-size: 14px;
            .customBtn {
              margin: 0;
            }
            div {
              padding-bottom: 5px;
              border-bottom: 0.5px solid rgba(255,255,255, 0.25);
              margin-bottom: 5px;
              color: #fff;
              display: flex;
              justify-content: space-between;
              align-items: center;
              &:first-child {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
                color: #fff;
                div{
                  border: none;
                  &:first-child {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    span {
                      &:last-child {
                        font-size: 12px;
                      }
                    }
                  }
                  &:last-child {
                    width: 133px;
                    height: 30px;
                    border: 1px solid #fff;
                    border-radius: 5px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px;
                  }
                }
              }
              &:nth-child(5) {
                border: none;
                justify-content: flex-start;
                input {
                  margin-right: 10px;
                }
              }
            }
          }
        }
        @media(min-width: 992px) and (max-width: 1400px) {
          height: 625px;
        }
      }
      &--highestProfits {
        height: 465px;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
        &--header {
          height: 97px;
          background-color: #17cd4a;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          color: #fff;
          // @media(min-width: 1400px) {
          //   align-items: center;
          // }
          &--title {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
          }
          &--range {
            background-color: #18a840;
            border-radius: 10px;
            overflow: hidden;
            display: flex;
            margin-top: 10px;
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 89px;
              height: 25px;
              border-right: 1px solid rgba(0, 0, 0, 0.15);
              font-size: 14px;
              &:last-child {
                border-right: none;
              }
            }
          }
        }
        &--title {
          height: 25px;
          background-color: #1d1d1b;
          padding: 0 8px;
          font-size: 14px;
          color: #fff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media(max-width: 1200px) {
            span {
              &:nth-child(2) {
                margin-left: 20px;
              }
            }
          }
        }
        &--table {
          height: 100%;
          padding: 0 8px;
          font-size: 12px;
          &--item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 34.5px;
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
            &--order {
              display: flex;
              span {
                &:first-child {
                  color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #464a5c;
                  width: 16px;
                  height: 16px;
                  border-radius: 50%;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
      @media(max-width: 993px) {
      width: 100%;
    }
    }
    @media(max-width: 993px) {
      flex-direction: column;

    }
  }
}
.sportsBookLineItem {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 234px;
  font-size: 14px;
  font-weight: normal;
  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}
.sportsbookActiveTab {
  color: #fff;
  background-image: linear-gradient(84deg, #1631ba 0%, #863cc4 100%) !important;
  div {
    background-image: linear-gradient(84deg, #fe6740 0%, #f29626 100%) !important;
  }
}
.activeBetsBoxTab {
  background-color:#333645;
}

.firstOrder {
  background-color: #ffd700 !important;
  color: #464a5c !important;
}
.secondOrder {
  background-color: #c0c0c0 !important;
  color: #464a5c !important;
}
.thirdOrder {
  background-color: #cd7f32 !important;
  color: #464a5c !important;
}
.ratio-black {
  color: #fff !important;
  background-color: #333645 !important;
} 