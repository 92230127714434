.aboutUs {
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
  &--inner {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    &--menubtn {
      .customBtn {
        margin-left: 0;
      }
      margin-bottom: 30px;
      @media (min-width: 991px) {
        display: none !important;
      }
    }
    &--links {
      width: 23%;
      height: 100%;
      @media (max-width: 991px) {
        display: none;
      }
    }
    &--content {
      width: 75%;
      height: 100%;
      @media (max-width: 991px) {
        width: 100%;
      }
    }
    @media (max-width: 991px) {
      flex-direction: column;
      width: 100%;
    }
  }
  &--aboutUsMobileMenu {
    background-image: linear-gradient(84deg, #863cc4 0%, #1631ba 100%);
    .tabItem--title {
      color: #fff;
      font-weight: normal;
    }
    .tabItem--dot {
      background-color: #fff;
    }
    .btn-close {
      margin-top: 20px !important;
      opacity: 1;
      background: url(../../assets/icons/close.svg) center/1em auto no-repeat;
    }
    .offcanvas-title {
      margin-top: 27px !important;
    }
  }
}
