.footer {
    width: 100%;
    background-color: rgba(34, 34, 34, 1);
    background-image: url(../../../assets/png/footerbg.svg);
    position: relative;
    &--top {
        width: 100%;
        height: 159px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        &--angleUpIcon {
            cursor: pointer;
            position: absolute;
            top: 26px;
        }
    }
    &--mid {
        display: flex;
        align-items: center;
        // margin: 50px 0 49px 0;
        min-height: 330px;
        padding-top: 30px;
        padding-bottom: 30px;
        &--inner {
            display: flex;
            justify-content: space-between;
        }
        @media(max-width: 1399px) {
            &--inner {
                flex-wrap: wrap ;
            }            
        }
    }
    &--bottom {
        width: 100%;
        min-height: 247px;
        border-top: 1px solid rgba(255, 255, 255, 0.15);
        display: flex;
        align-items: center;
        p{
            color: #fff;
            margin: 0;
            margin-top: 50px;
            text-align: center;
            font-size: 14px !important;
            font-weight: normal;
            line-height: normal;
            letter-spacing: normal;
        }
        &--logos {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 25px;
            img {
                margin-right: 50px;
            }
            @media(max-width: 992px) {
                flex-wrap: wrap;
                justify-content: space-between;
                img {
                    margin-right: 0;
                }
            }
            @media(max-width: 470px) {
                flex-wrap: wrap;
                justify-content: center;
                img {
                    margin-right: 15px;
                }
            }
        }
    }
    &--designer-sign {
        width: 100%;
        border-top: 1px solid rgba(255, 255, 255, 0.15);
        height: 58px;
        &--inner {
            display: flex;
            height: 57px;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 14px;
            p {
                margin: 0;
            }
        }
    }
}