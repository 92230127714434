.latestEventsLine {
    width: 100%;
    height: 57px;
    margin-top: 20px;
    border-radius: 10px;
    background-image: linear-gradient(87deg, #863cc4 0%, #1631ba 100%);
    padding: 0 20px;
    display: flex;
    align-items: center;
    &--item {
        min-width: 201.7px;
        height: 34.2px;
        display: flex !important;
        align-items: center;
        img {
            max-width: 68.4px;
            height: 34.2px;
        }
        &--content {
            margin-left: 5.7px;
            font-size: 12px !important;
            text-align: left;
            letter-spacing: -0.6px;
            color: #fff;
            p {
                margin: 0;
                line-height: 14px;
                &:first-child {
                    font-weight: 500;
                }
                &:last-child {
                    font-weight: 300;
                }
            }
        }
    }
}